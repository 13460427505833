import React from 'react';

import TextQuestion from './TextQuestion';
import InfoMessage from './InfoMessage';
import RadioQuestion from './RadioQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import DateQuestion from './DateQuestion';
import { filterAutoUnselectedFields, parseNameValue } from '../safetyQuestionFunctions';

interface QuestionRowProps {
  question: any;
  checkinContext: any;
  piiContext: any;
  updateCheckinContext: (reduxObj: any) => void;
  checkIfShownWhenTrue: (question: any) => boolean;
}

const QuestionRow: React.FC<QuestionRowProps> = ({
  question,
  checkinContext,
  piiContext,
  updateCheckinContext,
  checkIfShownWhenTrue,
}: QuestionRowProps) => {
  const hideNested = (value) => {
    const removeKeyFromCheckinContext = (key) => {
      // eslint-disable-next-line no-param-reassign
      checkinContext[key] = '';
    };
    Object.keys(checkinContext)
      .filter((key) => (key.includes(value) ? key : null))
      .map((key) => removeKeyFromCheckinContext(key));
  };

  const handleCheck = (checkValue) => {
    let reduxObj;
    const { name, value } = parseNameValue(checkValue);

    if (name.includes('Type') && name !== 'ReasonType' && !Array.isArray(checkinContext[name])) {
      reduxObj = { ...checkinContext, [name]: [value] };
    } else if (Array.isArray(checkinContext[name])) {
      const state = checkinContext[name];
      const index = state.indexOf(value);

      if (index === -1) {
        state.push(value);
      } else {
        state.splice(index, 1);
        hideNested(value);
      }
      reduxObj = { ...checkinContext, [name]: state };
    } else if (checkinContext[name] !== value) {
      reduxObj = filterAutoUnselectedFields(checkinContext, name, value)
    }
    if (reduxObj) {
      updateCheckinContext(reduxObj);
    }
  };

  if (!checkIfShownWhenTrue(question)) {
    return null;
  }

  const parent = question.field;
  const value = checkinContext[parent];

  const renderMainQuestion = () => {
    switch (question.type) {
      case 'datepicker':
        const handleDateInput = (name, timeChange) => {
          if (timeChange) {
            updateCheckinContext({
              [name]: timeChange.hours(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
            });
          }
        };
        return (
          <DateQuestion
            title={question.title}
            value={value}
            parent={parent}
            handleCheck={handleDateInput}
          />
        );
      case 'text':
        const handleTextInput = (name, value) => {
          updateCheckinContext({ [name]: value });
        };
        return (
          <TextQuestion
            handleCheck={handleTextInput}
            title={question.title}
            value={value}
            parent={parent}
            show={question.show}
            width={question.width}
          />
        );
      case 'info':
        return (
          <InfoMessage
            text={question.text}
            bold={question.title}
            backgroundColor={question?.config?.color}
          />
        );
      case 'radio':
        return (
          <RadioQuestion
            title={question.title}
            options={question.options}
            handleCheck={handleCheck}
            parent={parent}
            show={question.show}
            value={value}
          />
        );
      case 'choice':
        return (
          <CheckboxQuestion
            title={question.title}
            options={question.options}
            handleCheck={handleCheck}
            parent={parent}
            show={question.show}
            value={value}
          />
        );
      default:
        return null;
    }
  };
  const renderSubquestions = () =>
    question.subs && question.subs.map((subQuestion, index) => (
      <QuestionRow
        question={subQuestion}
        checkinContext={checkinContext}
        piiContext={piiContext}
        updateCheckinContext={updateCheckinContext}
        checkIfShownWhenTrue={checkIfShownWhenTrue}
        key={`${subQuestion.field}-${index}-row`}
      />
    ));
  return (
    <>
      {renderMainQuestion()}
      {renderSubquestions()}
    </>
  );
};

export default QuestionRow;
