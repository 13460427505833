import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fetchAppsResource, fetchPiiResource } from 'packages/apis';
import { StudyContext } from 'packages/booking-contexts';
import { InfoCard, Button, colors, Text } from 'shared-library';

const MachineNumber = styled.span`
  background-color: ${colors.primary} !important;
  border: 1px solid ${colors.smoke} !important;
  border-radius: 50%;
  color: ${colors.white} !important;
  display: inline-block;
  margin: 0 0 0 4px;
  padding: 0 5px;
  width: 20px;
`;

const Slot = styled(Button)`
  ${(props) =>
    props.variant === 'outline-reverse'
      ? `border-color: ${colors.smoke} !important; color: ${colors.ash} !important;`
      : ''}

  padding-left: 21px;
  padding-right: 21px;
  width: 130px;
  margin-bottom: 16px;

  &:hover {
    background-color: ${colors.primaryHover} !important;
    color: ${colors.white} !important;
    border-color: ${colors.primaryHover} !important;
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const addHoldEvent = async (admin, utcStart, utcEnd, calendars) => {
  const url = admin ? '/calendar/createHoldAssisted' : '/calendar/createHoldEvent';
  const calendarID = calendars[0].calendarID;
  const machineID = calendars[0].machineID;
  const { parsedBody: holdEvent } = await fetchAppsResource(url, 'POST', {
    utcStart,
    utcEnd,
    calendarId: calendarID,
  });
  if (holdEvent.status === 400) {

    const availableCalendars = calendars.filter(calendar => calendar.calendarID !== calendarID)
    if (availableCalendars.length){
      return await addHoldEvent(admin, utcStart, utcEnd, availableCalendars);
    }
    return holdEvent;
  }
  let { parsedBody: checkAvailableCalendarSlot } = await fetchAppsResource(
        `/calendar/checkAvailableCalendarSlot?calendarId=${calendarID}&utcStart=${utcStart}&utcEnd=${utcEnd}&maxAllowedEvents=1`,
        'GET',
        );
  if (checkAvailableCalendarSlot.status === 400) {
    fetchAppsResource(`/calendar/${calendarID}/event/${holdEvent.eventID}/delete`, 'POST', {});
    const availableCalendars = calendars.filter(calendar => calendar.calendarID !== calendarID)
    if (availableCalendars.length){
      return await addHoldEvent(admin, utcStart, utcEnd, availableCalendars);
    }
    return checkAvailableCalendarSlot;
  }
  return {...holdEvent, machineID};
}

export const selectTimeSlot = async (
  slot,
  selectedTime,
  setSelectedTime,
  selectedLocation,
  setManualTimeClear,
  eventId,
  admin,
  setOpenErrorDialog,
  calendarId,
  updateStudyContext,
) => {
  try {
    const { machines, utcEnd, utcStart } = slot;
    const newSelectedTime = selectedTime === utcStart ? null : utcStart;
    const selectedMachine = selectedLocation?.machines?.find(
      // for MMS it should currently take always the first machine in the list as the list is
      //  sorted according to the machine's priority within the back-end
      (machine) => machine.machineID === machines[0],
    );

    // resetting the timer for the previous hold event?
    if (setManualTimeClear) {
      setManualTimeClear(true);
      updateStudyContext({slotSelectionType: 'slot'})
    }

    // case if slot is changed
    if (calendarId && eventId) {
      fetchAppsResource(`/calendar/${calendarId}/event/${eventId}/delete`, 'POST', {});
      setSelectedTime(null, null)
    }


    // case if slot is unselected
    if (calendarId && eventId && !newSelectedTime){
      fetchAppsResource(`/calendar/${calendarId}/event/${eventId}/delete`, 'POST', {});
      setSelectedTime(null, null)
      return updateStudyContext({ eventID: null, calendarID: null });
    }

    setSelectedTime(newSelectedTime, selectedMachine.machineID);

    // get all calendars from response
    const availableMachines = selectedLocation?.machines.filter(machine => slot.machines.includes(machine.machineID) );
    let calendars = availableMachines.map((machine) => {
      return {machineID: machine.machineID, calendarID: machine.calendar}
    });
    const holdEvent = await addHoldEvent(admin, utcStart, utcEnd, calendars);
    if (holdEvent.status === 400) {
      setSelectedTime(null, null)
      setOpenErrorDialog(true);
    } else {
      return updateStudyContext({ eventID: holdEvent.eventID, calendarID: holdEvent.calendarId, machineId: holdEvent.machineID });
    }
  } catch (error) {
    throw Error(error);
  }
};

export const SlotsForDay = ({ slots, setSelectedTime, admin, setManualTimeClear }) => {
  const { updateStudyContext ,selectedLocation, appointmentTime: selectedTime, calendarID: calendarId, eventID: eventId} = useContext(StudyContext);

  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  if (slots.length === 0) {
    return (
      <Text size="xsmall" color={colors.ash} align="center">
        No Spots Available
      </Text>
    );
  }

  return (
    <>
      {slots.map((slot) => (
        <Slot
          key={slot.utcStart}
          onClick={() =>
            selectTimeSlot(
              slot,
              selectedTime,
              setSelectedTime,
              selectedLocation,
              setManualTimeClear,
              eventId,
              admin,
              setOpenErrorDialog,
              calendarId,
              updateStudyContext,
            )
          }
          selected={selectedTime === slot.utcStart}
          size="xsmall"
          variant={selectedTime === slot.utcStart ? 'primary' : 'outline-reverse'}
        >
          {slot.hour}:{slot.min} {slot.partOfDay.toLowerCase()}
          {admin ? <MachineNumber>{slot.machines.length}</MachineNumber> : ''}
        </Slot>
      ))}
      {openErrorDialog ? (
        <InfoCard
          handleClose={() => setOpenErrorDialog(false)}
          headerText=""
          bodyText="Unfortunately the time slot you selected is unavailable. Please select a different time slot."
          icon={{
            name: 'alert',
            color: colors.error,
            size: 80,
            viewSize: 100,
          }}
        />
      ) : null}
    </>
  );
};

const slotShape = PropTypes.shape({
  hour: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  partOfDay: PropTypes.string.isRequired,
  utcStart: PropTypes.number.isRequired,
  utcEnd: PropTypes.number.isRequired,
});

SlotsForDay.propTypes = {
  slots: PropTypes.arrayOf(slotShape),
  setSelectedTime: PropTypes.func.isRequired,
  admin: PropTypes.bool,
  setManualTimeClear: PropTypes.func,
};

SlotsForDay.defaultProps = {
  slots: [],
  admin: false,
  setManualTimeClear: () => {},
};
