import React, { useEffect } from 'react';

import { Text, colors } from 'shared-library';
import styled from 'styled-components';
import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom';
import data from './SafetyQuestions.json';
import QuestionRow from './QuestionRow';
import {
  getRequiredQuestions,
  checkIfShownWhenTrue as showWhenTrue,
} from '../safetyQuestionFunctions';

const QuestionSection = styled.div<{ grey: boolean; clear: boolean }>`
  ${({ grey, clear }) =>
    grey && !clear
      ? 'background: rgba(242, 243, 247, 0.6)'
      : `background-color: ${clear && grey ? 'none' : colors.white}`};
`;

interface SafetyQuestionsProps {
  studyID: string;
  piiContext: any;
  checkinContext: any;
  updateCheckinContext: () => void;
  getRedux: (studyID) => void;
  setRequiredFields: (nestedQuestions: any[]) => void;
}

const SafetyQuestions: React.FC<SafetyQuestionsProps> = ({
  studyID,
  piiContext,
  checkinContext,
  updateCheckinContext,
  getRedux,
  setRequiredFields,
}: SafetyQuestionsProps) => {
  const { path } = useRouteMatch();
  const safetyQuestions = data.subs;

  useEffect(() => {
    if (studyID) {
      getRedux(studyID);
    }
  }, [studyID]);

  const checkIfShownWhenTrue = (question) => {
    return showWhenTrue({ question, checkinContext, piiContext });
  };

  useEffect(() => {
    const nestedQuestions = getRequiredQuestions({ checkinContext, piiContext });
    setRequiredFields(nestedQuestions);
  }, [checkinContext]);

  return (
    <>
      <Text weight="600">Please answer the following safety questions:</Text>
      {safetyQuestions.map((question, index) => (
        <QuestionSection
          grey={index % 2 === 0}
          clear={path === '/admin/booking'}
          key={`${question.field}-section`}
        >
          <QuestionRow
            question={question}
            checkinContext={checkinContext}
            piiContext={piiContext}
            updateCheckinContext={updateCheckinContext}
            checkIfShownWhenTrue={checkIfShownWhenTrue}
            key={`${question.field}-row`}
          />
        </QuestionSection>
      ))}
    </>
  );
};

const SafetyQuestionsWithRouter = (props) => (
  <BrowserRouter>
    <Switch>
      <Route path="/admin/booking" render={() => <SafetyQuestions {...props} />} />
      <Route path="/admin/frontdesk" render={() => <SafetyQuestions {...props} />} />
      <Route path="/booking/referral" render={() => <SafetyQuestions {...props} />} />
    </Switch>
  </BrowserRouter>
);

export default SafetyQuestionsWithRouter;
