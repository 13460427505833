import React from 'react';
import styled from 'styled-components';

import { Label, RadioGroup } from 'shared-library';

const SuppressActionsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightAlignedRadios = styled(RadioGroup.Input)`
  margin: 9px 0 9px 32px;
`;

interface SuppressEmailOrInvoiceProps {
  invoiceAvailable: boolean;
  customerEmailAvailable: boolean;
  suppressCustomerEmails: (string) => void;
  suppressInvoiceFunction: (string) => void;
  studyId: string;
}

const SuppressEmailOrInvoice = ({
  invoiceAvailable,
  customerEmailAvailable,
  suppressCustomerEmails,
  suppressInvoiceFunction,
  studyId,
}: SuppressEmailOrInvoiceProps) => {
  const suppressInvoice = typeof invoiceAvailable !== undefined && invoiceAvailable === false;
  const suppressEmail =
    typeof customerEmailAvailable !== undefined && customerEmailAvailable === false;

  return (
    <>
      <SuppressActionsDiv>
        <Label>Suppress Customer Emails?</Label>
        <RadioGroup
          name="suppress-email"
          initialValue={suppressEmail ? 'yes-suppress-email' : 'no-suppress-email'}
          onChange={suppressCustomerEmails}
        >
          <div>
            <RightAlignedRadios
              id="no-suppress-email"
              disabled={!studyId}
              key="no-suppress-email"
              label="No"
              value="no-suppress-email"
            />
            <RightAlignedRadios
              id="yes-suppress-email"
              disabled={!studyId}
              key="yes-suppress-email"
              label="Yes"
              value="yes-suppress-email"
            />
          </div>
        </RadioGroup>
      </SuppressActionsDiv>
      <SuppressActionsDiv>
        <Label>Suppress Invoice/Receipt?</Label>
        <RadioGroup
          name="suppress-invoice"
          initialValue={suppressInvoice ? 'yes-suppress-invoice' : 'no-suppress-invoice'}
          onChange={suppressInvoiceFunction}
        >
          <div>
            <RightAlignedRadios
              id="no-suppress-invoice"
              key="no-suppress-invoice"
              label="No"
              value="no-suppress-invoice"
              disabled={!studyId}
            />
            <RightAlignedRadios
              id="yes-suppress-invoice"
              key="yes-suppress-invoice"
              label="Yes"
              value="yes-suppress-invoice"
              disabled={!studyId}
            />
          </div>
        </RadioGroup>
      </SuppressActionsDiv>
    </>
  );
};

export default SuppressEmailOrInvoice;
