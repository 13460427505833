import React, { useContext, useEffect, useState } from 'react';
import { StudyContext } from 'packages/booking-contexts';
import { fetchHiResource, fetchPiiResource } from 'packages/apis';
import { useToasts } from 'react-toast-notifications';
import { Notification } from 'shared-library';

type UserType = {
  user: {
    roles: string[];
  };
};

interface PrescriptionContextProps {
  checkinContext?: any;
  updateCheckinContext?: (updateObj: any) => void;
  getRedux?: (studyID: string) => void;
  changeInContext?: boolean;
  requiredFields?: Array<string>;
  setRequiredFields?: (Array) => void;
  submitSafetyQuestions?: (studyID: string, onError?: any) => void;
  setPrescriptionTaken: (flag: boolean) => void;
  prescriptionTaken: boolean;
  updatePrescriptionTaken: () => any;
  currentUserRoles: string[];
  setWasPrescriptionTakenUpdated: (flag: boolean) => void;
}

export const PrescriptionContext = React.createContext<PrescriptionContextProps>({});

export const PrescriptionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { addToast } = useToasts();
  const { studyId, prenuvoId } = useContext(StudyContext);
  const [prescriptionTaken, setPrescriptionTaken] = useState(false);
  const [currentUserRoles, setCurrentUserRoles] = useState<string[]>([]);
  const [initialPrescriptionTaken, setInitialPrescriptionTaken] = useState(false);
  const [wasPrescriptionTakenUpdated, setWasPrescriptionTakenUpdated] = useState(false);

  useEffect(() => {
    fetchHiResource<boolean>(`/api/v1/prescription/${studyId}/prescriptionTaken`, 'GET').then(
      (res) => {
        setInitialPrescriptionTaken(res?.parsedBody);
        if (res?.parsedBody) {
          setPrescriptionTaken(res.parsedBody);
        }
      },
    );
    fetchPiiResource<UserType>(`/api/v1/userV2/current`).then((res) => {
      if (res?.parsedBody?.user?.roles) {
        setCurrentUserRoles(res.parsedBody.user.roles);
      }
    });
  }, []);

  const clearStates = () => {
    setWasPrescriptionTakenUpdated(false);
  };

  const updatePrescriptionTaken = async () => {
    try {
      if (wasPrescriptionTakenUpdated && initialPrescriptionTaken !== prescriptionTaken) {
        return await fetchHiResource(`/api/v1/prescription/${studyId}/prescriptionTaken`, 'PATCH', {
          prescriptionTaken,
          prenuvoId,
        });
      }
    } catch (error) {
      addToast(
        Notification.create('Sedative update failed.', `Please report this issue if it persists.`),
        { appearance: 'error' },
      );
      return {status: 400}
    } finally {
      clearStates();
    }
  };

  return (
    <PrescriptionContext.Provider
      value={{
        setPrescriptionTaken,
        prescriptionTaken,
        updatePrescriptionTaken,
        currentUserRoles,
        setWasPrescriptionTakenUpdated,
      }}
    >
      {children}
    </PrescriptionContext.Provider>
  );
};
