import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BookingContext, StudyContext, MEMBER_TYPE_OPTIONS } from 'packages/booking-contexts';
import { colors, SelectInput, Text, Notification } from 'shared-library';
import { formatUnixDatetime, titleCase } from 'packages/formatters';
import { PrescriptionContext } from 'packages/prescription/contexts';

const LocationMachineSkuDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  margin-bottom: 26px;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  opacity: ${({ opacity }) => opacity || 1} !important;
  box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
`;

const PRESCRIPTION_TAKEN_OPTIONS = [
  { value: false, label: 'No Sedative Taken' },
  { value: true, label: 'Sedative Taken' },
];

export const LocationMachineSku = ({ onChange }) => {
  const { locations, skus: allSkus, allowedLocations } = useContext(BookingContext);
  const { booking, paymentAmount, selectedLocation, skuID, memberType } = useContext(StudyContext);
  const {
    prescriptionTaken,
    setPrescriptionTaken,
    currentUserRoles,
    setWasPrescriptionTakenUpdated,
  } = useContext(PrescriptionContext);
  const [locationSelect, setLocationSelect] = useState([]);
  const [skuSelect, setSkuSelect] = useState([]);
  const { locationID } = selectedLocation;
  const memberTypeOptions = MEMBER_TYPE_OPTIONS.map((option) => ({ label: option, value: option }));
  const { path } = useRouteMatch();
  const showMemberTypeSelect = path.includes('/admin/booking');
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const { addToast } = useToasts();

  const filterOutNotAdminSku = (location) => {
    const { catalog } = location;
    const { primary } = catalog;

    return primary.map((sku) => sku.admin && sku.skuID).filter((sku) => !!sku);
  };

  const filterSkus = (location) => {
    const availableSkus = filterOutNotAdminSku(location);

    return allSkus
      .filter((sku) => availableSkus.includes(sku.skuID))
      .sort((a, b) => {
        return parseInt(a.skuID) - parseInt(b.skuID);
      })
      .map((sku) => {
        return { label: `${titleCase(sku.name)} (${sku.skuID})`, value: sku.skuID };
      });
  };

  const handleLocationChange = async (changeLocationOption, change = true) => {
    if (locations.length) {
      const changedLocationId =
        typeof changeLocationOption === 'string'
          ? changeLocationOption
          : changeLocationOption.value;

      const locationChange = locations.find(
        (location) => location.locationID === changedLocationId
      );

      const filteredSkus = filterSkus(locationChange);
      setSkuSelect(filteredSkus);

      if (change) {
        onChange({
          selectedLocation: locationChange,
          datetime: null,
        });
      }
    }
  };

  useEffect(() => {
    const getLocations = async () => {
      if (locationSelect.length === 0 && allowedLocations.length > 0 && locations.length > 0) {
        const allowedLocationSelect = locations
          .filter((location) => location.assistedBooking && allowedLocations.includes(location.locationID))
          .sort((a, b) => {
            return a.locationID < b.locationID ? -1 : a.locationID > b.locationID ? 1 : 0;
          })
          .map((location) => {
            return {
              value: location.locationID,
              label: `${location.address.name}, ${location.address.city} (${location.locationID})`,
            };
          });

        setLocationSelect(allowedLocationSelect);
      }
    };

    getLocations();
  }, [locations, allowedLocations]);

  useEffect(() => {
    if (locations.length && locationID) {
      handleLocationChange(locationID, false);
    }
  }, [locationID, locations]);

  useEffect(() => {
    const checkLocation = () => {
      const locationSelected = locationSelect.filter((location) => location.value === locationID);

      if(paymentAmount > 0){
        setIsSelectDisabled(true);
      }

      if (!locationSelected) {
        addToast(
          Notification.create('Location not allowed','The location is not allowed to your account'),
          { appearance: 'error' }
        );
      }

    }

    if (paymentAmount && locations.length && locationID) {
      checkLocation();
    }

  }, [paymentAmount, locations, locationID]);

  const onSelectPrescriptionTaken = (option) => {
    setPrescriptionTaken(option);
    setWasPrescriptionTakenUpdated(true);
  };

  return (
    <>
      <LocationMachineSkuDiv>
        {locationSelect.length > 0 && (
          <div data-tip="" data-for="paymentAmount">
            <SelectInput
              disabled={isSelectDisabled}
              id="location"
              label="Select Scan Location*"
              onChange={handleLocationChange}
              options={locationSelect}
              width="300px"
              value={locationSelect.filter((location) => location.value === locationID)}
              variant="primary"
            />

            {paymentAmount > 0 && (
              <StyledReactTooltip
                backgroundColor={colors.white}
                id="paymentAmount"
                textColor={colors.primary}
              >
                Location cannot be changed if payment was completed in full. Please refund the
                member, change location, and receive payment again.
              </StyledReactTooltip>
            )}
          </div>
        )}

        {skuSelect.length > 0 && (
          <SelectInput
            id="skuID"
            label="Select Scan Type*"
            onChange={(e) => onChange({ skuID: e.value })}
            options={skuSelect}
            value={skuSelect.filter((sku) => sku.value === skuID)}
            width="300px"
            variant="primary"
          />
        )}

        {currentUserRoles?.some((role) => role === 'frontdesk') && (
          <SelectInput
            label="Sedative"
            options={PRESCRIPTION_TAKEN_OPTIONS}
            onChange={(opt) => onSelectPrescriptionTaken(opt.value)}
            value={PRESCRIPTION_TAKEN_OPTIONS.find(
              (prescriptionOption) => prescriptionOption.value === prescriptionTaken,
            )}
            width="300px"
            variant="primary"
          />
        )}

        {skuSelect.length > 0 && showMemberTypeSelect && (
          <SelectInput
            id='memberType'
            disabled={booking?.calendarID ? true: false}
            label='Select Member Type'
            onChange={(e) => onChange({ memberType: e.value })}
            options={memberTypeOptions}
            value={memberTypeOptions.filter((option) => option.value === memberType)}
            width='300px'
            variant='primary'
          />
        )}

      </LocationMachineSkuDiv>

      {booking?.utcStart && (
        <Text>
          {`Current Appointment:
              ${formatUnixDatetime(booking?.utcStart, booking?.timezone)}
              ${booking?.displayTimezone}`}
        </Text>
      )}
    </>
  );
};

LocationMachineSku.propTypes = {
  onChange: PropTypes.func.isRequired,
};
