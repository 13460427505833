import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { colors, Icon, Text } from 'shared-library';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { getFutureMomentObj, getWeek } from '../calendarFunctions';

const WeekDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const WeekButtonContainer = styled.div`
  width: 140px;
  height: 70px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
  background-color: ${(props) => (props.selected ? colors.white : colors.smoke)};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => !props.selected && 'opacity: 0.5;'}

  &:hover {
    cursor: pointer;
    background-color: ${colors.white};
    opacity: 1;
  }
`;

const PreviousAndNextWeeksContainer = styled(WeekButtonContainer)`
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  flex-direction: row;
  box-shadow: none;
  background-color: transparent;
  opacity: 1;

  &:hover {
    color: ${colors.primaryHover} !important;
    background-color: transparent;
  }
`;

const HoverIcon = styled(Icon)`
  ${PreviousAndNextWeeksContainer}:hover & {
    path {
      fill: ${colors.primaryHover};
    }
  }
`;

const WeekOfText = styled(Text)`
  margin-top: 14px !important;
  margin-bottom: -10px !important;
  text-transform: uppercase;
`;

const DateText = styled(Text)`
  line-height: 28px !important;
`;

const PreviousNextText = styled(Text)`
  margin: 0 !important;
  ${PreviousAndNextWeeksContainer}:hover & {
    color: ${colors.primaryHover};
  }
`;

const WeekButton = ({ current, month, date, unixTimestamp, selected, setSelectedWeek }) => {
  return (
    <WeekButtonContainer selected={selected} onClick={() => setSelectedWeek(unixTimestamp)}>
      <WeekOfText
        size="xsmall"
        selected={selected}
        color={selected ? colors.charcoal : colors.ash}
        weight={700}
      >
        {current ? 'Current Week' : 'Week of'}
      </WeekOfText>
      <DateText
        size="large"
        color={selected ? colors.primary : colors.ash}
        selected={selected}
        weight={700}
      >
        {month} {date}
      </DateText>
    </WeekButtonContainer>
  );
};

WeekButton.propTypes = {
  current: PropTypes.bool,
  month: PropTypes.string,
  date: PropTypes.number,
  unixTimestamp: PropTypes.number,
  selected: PropTypes.bool,
  setSelectedWeek: PropTypes.func.isRequired,
};

WeekButton.defaultProps = {
  current: false,
  month: '',
  date: null,
  unixTimestamp: null,
  selected: false,
};

export const WeekOfHeader = ({ selectedWeek, setSelectedWeek, selectedMonth, selectedYear }) => {
  const [availableWeeks, setAvailableWeeks] = useState([]);

  const getAvailableWeeks = async (weekNum, momentObj) => {
    const weeks = [];
    const start = momentObj || moment().day(0);
    // eslint-disable-next-line no-plusplus
    for (let weekNumber = 0; weekNumber < weekNum; weekNumber++) {
      const sundayObj = getWeek(moment(start), weekNumber);
      weeks.push(sundayObj);
    }
    setAvailableWeeks(weeks);
    setSelectedWeek(weeks[0].unixTimestamp);
  };

  useEffect(() => {
    getAvailableWeeks(6);
  }, []);

  useEffect(() => {
    const momentObj = getFutureMomentObj(selectedMonth - 1, selectedYear);
    if (moment().diff(momentObj, 'days') <= 0) {
      getAvailableWeeks(5, momentObj);
    }
  }, [selectedMonth, selectedYear]);

  const getPreviousWeek = () => {
    const newAvailableWeeks = availableWeeks.slice(0, 4);
    const { unixTimestamp: currentFirstWeek } = availableWeeks[0];
    const start = moment(currentFirstWeek * 1000);
    const previousWeek = getWeek(start, -1);
    newAvailableWeeks.splice(0, 0, previousWeek);
    setAvailableWeeks(newAvailableWeeks);
  };

  const getNextWeek = () => {
    const sliceNum = availableWeeks.length - 4;
    const newAvailableWeeks = availableWeeks.slice(sliceNum);
    const lastWeek = availableWeeks.slice(-1)[0];
    const { unixTimestamp: currentLastWeek, weekNumber: currentLastWeekNumber } = lastWeek;
    const weekNumber = currentLastWeekNumber + 1;
    const start = moment(currentLastWeek * 1000);
    const nextWeek = getWeek(start, 1);
    nextWeek.weekNumber = weekNumber;
    newAvailableWeeks.push(nextWeek);
    setAvailableWeeks(newAvailableWeeks);
  };

  return (
    <WeekDiv>
      {!availableWeeks.find((week) => week.current === true) && (
        <PreviousAndNextWeeksContainer onClick={getPreviousWeek}>
          <HoverIcon type="caret-left" color={colors.primary}/>
          <PreviousNextText size="small" color={colors.primary}>
            Previous Weeks
          </PreviousNextText>
        </PreviousAndNextWeeksContainer>
      )}
      {availableWeeks.map(({ current, month, date, unixTimestamp }) => (
        <WeekButton
          current={current}
          month={month}
          date={date}
          unixTimestamp={unixTimestamp}
          selected={moment(unixTimestamp * 1000).diff(selectedWeek * 1000, 'days') === 0}
          setSelectedWeek={setSelectedWeek}
          key={unixTimestamp}
        />
      ))}
      <PreviousAndNextWeeksContainer onClick={getNextWeek}>
        <PreviousNextText size="small" color={colors.primary}>
          Next Weeks
        </PreviousNextText>
        <HoverIcon type="caret-right" color={colors.primary}/>
      </PreviousAndNextWeeksContainer>
    </WeekDiv>
  );
};

WeekOfHeader.propTypes = {
  selectedWeek: PropTypes.number,
  setSelectedWeek: PropTypes.func.isRequired,
  selectedMonth: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired,
};

WeekOfHeader.defaultProps = {
  selectedWeek: null,
};
