import React, { useContext } from 'react';

import { colors, Icon, Text } from 'shared-library';
import { StudyContext } from 'packages/booking-contexts';

export const TimezoneIndicator = () => {
  const { selectedLocation } = useContext(StudyContext);

  return (
    <>
      {selectedLocation?.displayTimezone && selectedLocation?.timezone && (
        <Text size="small" color={colors.ash}>
          <Icon type="world" color={colors.primary} />

          {` ${selectedLocation?.displayTimezone}, US/Canada`}
        </Text>
      )}
    </>
  );
};
