import { Label, TextInput, colors } from 'shared-library';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckinContext } from '../CheckinContext';
import { Column, QuestionLayoutRow } from './Layout';

interface TextQuestionProps {
  title: string;
  handleCheck: (parent: string, textValue: string) => void;
  parent: string;
  show: string;
  value: string;
  width: string;
}

const StyledTextInput = styled(TextInput)`
  ${({ highlighted }) => highlighted && `box-shadow: 0 0 10px ${colors.secondary};`}
`;

const TextQuestion: React.FC<TextQuestionProps> = ({
  handleCheck,
  title,
  value,
  parent,
  show,
  width
}: TextQuestionProps) => {
  const { requiredFields, checkinContext } = useContext(CheckinContext);
  const [highlighted, setHighlighted] = useState(false);
  const required = parent !== 'ReasonForVisit';

  useEffect(() => {
    const checkHighlighted = required && requiredFields.includes(parent) && !checkinContext[parent];
    if (highlighted !== checkHighlighted) {
      setHighlighted(checkHighlighted);
    }
  }, [checkinContext, requiredFields]);

  return (
    <QuestionLayoutRow
      columns={required ? 0 : null}
      margin={show !== 'always' ? '1em 2em' : '20px auto auto'}
    >
      <Column>
        <Label>{title}</Label>
      </Column>
      <Column>
        <StyledTextInput
          name={parent}
          required={required}
          value={value}
          onChange={(checkValue) => handleCheck(parent, checkValue)}
          highlighted={highlighted}
          width={width}
        />
      </Column>
    </QuestionLayoutRow>
  );
};

export default TextQuestion;
