import styled from 'styled-components';

import { Text, colors } from 'shared-library';

export const Column = styled.div<{ full?: boolean; margin?: string }>`
  width: 100%;
  ${({ full }) => !full && 'padding-right: 16px;'}
  margin-bottom: 16px;
  justify-content: left;
  ${({ margin }) => margin && `margin: ${margin}`};
`;

export const Label = styled(Text).attrs({
  size: 'small',
  color: colors.ash,
  weight: '600',
})`
  margin-bottom: 1px;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QuestionLayoutRow = styled.div<{ columns?: number; margin?: string }>`
  display: grid;
  row-gap: 20px;
  margin: 20px 0;

  > p {
    word-wrap: break-word;
  }

  ${({ columns }) =>
    columns
      ? `grid-template-columns: ${100 - 17 * columns}% repeat(${columns}, 17%)`
      : 'grid-template-columns: 100%; row-gap: 0'};
  ${({ margin }) => margin && `margin: ${margin}`};
`;
