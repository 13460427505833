import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import { BookingContext, StudyContext } from 'packages/booking-contexts';
import { DatePicker, colors, Icon, SelectInput, Text } from 'shared-library';

const ManualTimeToggleDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const ManualTimeDiv = styled(ManualTimeToggleDiv)`
  width: 100%;
  padding-bottom: 16px;
`;

const StyledToggle = styled(Text)`
  padding-left: 10px;
  text-decoration: underline;

  ${ManualTimeToggleDiv}:hover & {
    cursor: pointer;
    color: ${colors.primaryHover};
  }
`;

const HoverIcon = styled(Icon)`
  margin-top: 3px;

  ${ManualTimeToggleDiv}:hover & {
    path {
      fill: ${colors.primaryHover} !important;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  margin-right: 66px;
`;

const StyledSelectInput = styled(SelectInput)`
  margin-left: 35px;
`;

const getManualTimeOptions = () => {
  const slots = [{ label: '', value: null }];
  let slot = 0;

  while (slot < 24 * 60 * 60) {
    let hour = parseInt(slot / 60 / 60);

    const ampm = hour >= 12 ? 'pm' : 'am';
    const minute = (slot / 60) % 60;

    slots.push({
      value: hour * 100 + minute,
      label: `${hour === 0 ? 12 : hour > 12 ? (hour -= 12) : hour}:${
        minute === 5 ? '05' : minute || '00'
      } ${ampm}`,
    });

    slot += 5 * 60;
  }

  return slots;
};

export const ManualTime = ({ manualTimeClear, setManualTimeClear, setSelectedTime, skuID }) => {
  const { locations } = useContext(BookingContext);
  const { machineID, selectedLocation, updateStudyContext } = useContext(StudyContext);
  const { locationID, timezone } = selectedLocation;

  const defaultManualTime = {
    minute: null,
    hour: null,
    date: null,
    month: null,
    year: null,
  };

  const [manualTimeNew, setManualTimeNew] = useState(defaultManualTime);

  const manualTimeOptions = getManualTimeOptions();
  const [machines, setMachines] = useState([]);

  useEffect(() => {
    if (manualTimeClear) {
      setManualTimeNew(defaultManualTime);
      setManualTimeClear(false);
    }
  }, [manualTimeClear]);

  useEffect(() => {
    if (locations.length && locationID) {
      const location = locations.find((location) => location.locationID === locationID);
  
      if (location) {
        const machineOptions = location.machines
          .filter((machine) => {
            // Only include machines that have at least one sku that contains selected SKU
            if (machine && machine.availableSKUs.length) {
              return machine.availableSKUs.some((sku) => sku === skuID);
            }
            return true;
          })
          .map((machine) => ({
            label: `${machine.name} (${machine.machineID})`,
            value: machine.machineID,
          }));
  
        setMachines(machineOptions);
  
        if (machineOptions.length) {
          updateStudyContext({ machineId: machineOptions[0].value });
        }
      }
    }
  }, [locations, locationID, skuID]);

  const handleDateChange = (timeChange) => {
    if (timeChange) {
      setManualTimeNew({
        ...manualTimeNew,
        month: timeChange.month(),
        date: timeChange.date(),
        year: timeChange.year(),
      });
    }
  };

  const getDatePickerDate = () => {
    if (manualTimeNew.month >= 0 && manualTimeNew.date && manualTimeNew.year) {
      return moment([manualTimeNew.year, manualTimeNew.month, manualTimeNew.date]);
    }
    return null;
  };

  const handleTimeChange = ({ value: timeChange }) => {
    const hour = Math.floor(timeChange / 100);
    const minute = timeChange % 100;
    setManualTimeNew({
      ...manualTimeNew,
      hour,
      minute,
    });
  };

  const getSelectedTime = () =>
    manualTimeNew &&
    !Number.isNaN(parseInt(manualTimeNew.hour, 10)) &&
    (manualTimeNew.hour >= 0 || manualTimeNew.minute >= 0) &&
    manualTimeOptions.find(
      (option) => option.value === manualTimeNew.hour * 100 + manualTimeNew.minute,
    );

  useEffect(() => {
    if (manualTimeNew && getDatePickerDate() && getSelectedTime()) {
      moment.tz.setDefault(timezone);
      setSelectedTime(
        moment
          .tz(getDatePickerDate(), timezone)
          .hour(manualTimeNew.hour)
          .minute(manualTimeNew.minute)
          .format('X'),
      );
      updateStudyContext({ slotSelectionType: 'manual' });
    }
  }, [manualTimeNew]);

  return (
    <>
      <Text>Manual Time</Text>

      <ManualTimeDiv>
        <StyledDatePicker
          isOutsideRange={() => false}
          label="Select Date*"
          onChange={handleDateChange}
          value={getDatePickerDate()}
          width="145px"
        />

        <SelectInput
          key="selectTime"
          label="Select Time*"
          onChange={handleTimeChange}
          options={manualTimeOptions}
          value={getSelectedTime()}
          width="135px"
        />

        {machines.length > 0 && (
          <StyledSelectInput
            key="machineID"
            label="Select Machine*"
            onChange={(e) => updateStudyContext({ machineId: e.value })}
            options={machines}
            value={machines.filter((machine) => machine.value === machineID)}
            width="250px"
          />
        )}
      </ManualTimeDiv>
    </>
  );
};

ManualTime.propTypes = {
  manualTimeClear: PropTypes.bool,
  setManualTimeClear: PropTypes.func.isRequired,
  setSelectedTime: PropTypes.func.isRequired,
};

ManualTime.defaultProps = {
  manualTimeClear: false,
};

export const ManualTimeToggle = ({ handleManualTimeShow }) => (
  <ManualTimeToggleDiv>
    <HoverIcon type="add" color={colors.primary} />

    <StyledToggle onClick={handleManualTimeShow} color={colors.primary} weight="500" size="small">
      Add a Manual Time
    </StyledToggle>
  </ManualTimeToggleDiv>
);

ManualTimeToggle.propTypes = {
  handleManualTimeShow: PropTypes.func.isRequired,
};
