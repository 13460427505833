import React, { useState } from 'react';

export const LoadingContext = React.createContext();

export const LoadingContextProvider = ({ children }) => {
  const [loadingModal, setLoadingModal] = useState('');
  const [closeLoadingAvailable, setCloseLoadingAvailable] = useState(false);
  const setLoading = (newLoadingText) => {
    setLoadingModal(newLoadingText);
    if (
      newLoadingText.toLowerCase().includes('error') ||
      newLoadingText.toLowerCase().includes('fail') ||
      newLoadingText.toLowerCase().includes('missing')
    ) {
      setCloseLoadingAvailable(true);
    }
  };
  return (
    <LoadingContext.Provider
      value={{ loadingModal, setLoading, closeLoadingAvailable, setCloseLoadingAvailable }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
