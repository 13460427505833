import { Text, DatePicker } from 'shared-library';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Column } from './Layout';

const QuestionLayoutRow = styled.div`
  display: grid;
  row-gap: 20px;
  margin: 1em 2em;

  > p {
    word-wrap: break-word;
  }
  grid-template-columns: 65% 35%;
  // grid-template-columns: 66% 25%;;
`;

const QuestionLabel = styled(Text)`
  padding-left: 20px;
`;

interface DateQuestionProps {
  title: string;
  value: any;
  parent: string;
  handleCheck: (string, moment) => void;
}

const DateQuestion: React.FC<DateQuestionProps> = ({
  title,
  value,
  parent,
  handleCheck,
}: DateQuestionProps) => {
  return (
    <QuestionLayoutRow>
      <Column>
        <QuestionLabel noMargin>{title}</QuestionLabel>
      </Column>
      <Column>
        <DatePicker
          value={value ? moment(value) : null}
          onChange={(dateChange) => handleCheck(parent, dateChange)}
          isOutsideRange={() => false}
          numberOfMonths={1}
        />
      </Column>
    </QuestionLayoutRow>
  );
};

export default DateQuestion;
