import { fetchHiResource } from 'packages/apis';
import data from './SafetyQuestions/SafetyQuestions.json';

export const getRedux = async (studyID: string) => {
  const { parsedBody: redux } = await fetchHiResource(
    `/api/booking/safety-questions?studyID=${studyID}`,
    'GET',
  );
  if (redux) {
    Object.keys(redux).forEach((field) => {
      const fieldValue = redux[field];
      const replacedField = field.startsWith('Safety.SafetyQuestions.')
        ? field.replace('Safety.SafetyQuestions.', '')
        : field.startsWith('Safety.PatientData.')
        ? field.replace('Safety.PatientData.', '')
        : '';
      if (replacedField) {
        redux[replacedField] = fieldValue;
        delete redux[field];
      }
    });
  }
  return redux;
};

export const checkIfShownWhenTrue = ({ question, checkinContext, piiContext }) => {
  if (question?.show === 'always') {
    return true;
  }
  const showWhen = question?.showWhen;
  if (showWhen?.field) {
    const { value, field, operator } = showWhen;
    if (operator === 'contains') {
      return checkinContext && checkinContext[field] && checkinContext[field].includes(value);
    }
    if (field === 'gender') {
      // eslint-disable-next-line no-eval
      return piiContext && eval(`"${piiContext[field]}" ${operator} "${value}"`);
    }
    // eslint-disable-next-line no-eval
    return checkinContext && eval(`"${checkinContext[field]}" ${operator} "${value}"`);
  }
  return false;
};

export const getRequiredQuestions = ({ checkinContext, piiContext }) => {
  const safetyQuestions = data.subs;
  const nestedQuestions = [];
  const nestedSubs = (question) => {
    if (checkIfShownWhenTrue({ question, checkinContext, piiContext })) {
      if (question.type !== 'info') {
        if (question.required) {
          nestedQuestions.push(question.field);
        }
      }
    }
    if (question?.subs) {
      question.subs.map((q) => nestedSubs(q));
    }
  };

  safetyQuestions.map((question) => nestedSubs(question));
  if (nestedQuestions.includes('ReasonForVisit')) {
    nestedQuestions.splice(nestedQuestions.indexOf('ReasonForVisit'), 1);
  }

  return nestedQuestions;
};

export const checkIfRequiredQuestionsComplete = ({ checkinContext, piiContext }) => {
  const requiredFields = getRequiredQuestions({ checkinContext, piiContext });
  return requiredFields.map((field) => !checkinContext[field]).filter((field) => !!field).length;
};

export const parseNameValue = (
  checkValue: string | { target: { name: string; value: string } },
) => {
  let value;
  let name;
  if (typeof checkValue === 'string') {
    const splitName = checkValue.split('.');
    value = splitName.pop();
    name = splitName.join('.');
  } else {
    const { target } = checkValue;
    name = target.name;
    value = target.value;
  }
  return { name, value };
};

export const filterAutoUnselectedFields = (checkinContext, name, value) => {
  const reduxObj = { ...checkinContext, [name]: value };
  const keysToDelete = [];
  const keysToSetNull = [];
  if (
        [
          'MedicalMetal.Heart.Surgery',
          'MedicalMetal.Spine.Surgery',
          'MedicalMetal.Head.Surgery',
        ].includes(name) &&
        value === 'no' &&
        checkinContext[`${name.split('.').slice(0, -1).join('.')}.Type`] &&
        checkinContext[`${name.split('.').slice(0, -1).join('.')}.Type`] !== []
      ) {
        reduxObj[`${name.split('.').slice(0, -1).join('.')}.Type`] = [];
        Object.keys(reduxObj).forEach((key) => {
          if (
            key.includes(`${name.split('.').slice(0, -1).join('.')}.`) &&
            key !== `${name.split('.').slice(0, -1).join('.')}.Type` &&
            key !== `${name.split('.').slice(0, -1).join('.')}.Surgery`
          ) {
            keysToDelete.push(key);
          }
        });
      }
      if (name === 'NonMedicalMetal.Eyes.Injury' && value === 'no') {
        keysToDelete.push('NonMedicalMetal.Eyes.Removed', 'NonMedicalMetal.Eyes.MRISince')
      }
      if (name === 'Claustrophobic.Status' && value === 'no') {
        keysToSetNull.push('Claustrophobic.OralSedative', 'Claustrophobic.OralSedativePrescription')
      }
      if (name === 'Allergies.Status' && value === 'no') {
        keysToSetNull.push('Allergies.Types')
      }
      if (name === 'Medications.Current' && value === 'no') {
        keysToSetNull.push('Medications.Types')
      }

    if (keysToDelete.length) {
      keysToDelete.forEach((key)=>delete reduxObj[key])
    }
    if (keysToSetNull.length) {
      keysToSetNull.forEach((key)=> {
        reduxObj[key] = null
      })
    }
    return reduxObj
}