import styled from 'styled-components';
import { colors } from 'shared-library';

export const CalendarFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const CalendarHeader = styled(CalendarFooter)`
  margin-bottom: 12px;
`;

export const TimezoneIndicatorDiv = styled.div`
  margin-top: 24px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid ${colors.cloud};
  border-top-left-radius: 50px;
  margin-bottom: 12px;
`;

export const TableHead = styled.thead`
  border: 1px solid ${colors.cloud};
  border-top-left-radius: 50px;
`;

export const TableHeadRow = styled.tr``;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${colors.white};
  }

  &:nth-child(even) {
    background-color: ${colors.pearl};
  }
`;

export const TableHeader = styled.th`
  &:first-child {
    border-top-left-radius: 50px;
  }
  &:last-child {
    border-top-right-radius: 50px;
  }
  color: ${(props) => (props.current ? colors.charcoal : colors.ash)};
  font-size: 14px;
  font-weight: 700;
  padding: 11px 20px;
  letter-spacing: 0px;
  line-height: 17px;
  text-align: center;
`;

export const TableCell = styled.td`
  color: ${colors.ash};
  line-height: 30px;
  padding: 20px 9px;
  width: ${100 / 7}%;
  vertical-align: text-top;
  border: solid 1px ${colors.cloud};
`;

export const YearMonthDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  margin-bottom: 26px;
`;

export const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 24px auto 12px;
`;

export const FooterDiv = styled.div`
  margin: 24px auto 12px;
`;
