import React, { useContext, useState } from 'react';
import { PiiContext } from 'packages/booking-contexts';
import { fetchAppsResource, fetchHiResource, fetchPiiResource } from 'packages/apis';
import { getRedux as getReduxFunction } from '../safetyQuestionFunctions';

export const CheckinContextShape = {};

interface CheckinContextProps {
  checkinContext?: any;
  updateCheckinContext?: (updateObj: any) => void;
  getRedux?: (studyID: string) => void;
  changeInContext?: boolean;
  requiredFields?: Array<string>;
  setRequiredFields?: (Array) => void;
  submitSafetyQuestions?: (studyID: string, onError?: any) => void;
}

export const CheckinContext = React.createContext<CheckinContextProps>({});

export const CheckinContextProvider = ({ children }: any) => {
  const [checkinContext, setCheckinContext] = useState(CheckinContextShape);
  const [checkinContextOnHi, setCheckinContextOnHi] = useState(null);
  const [changeInContext, setChangeInContext] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const { piiContext } = useContext(PiiContext);

  const updateCheckinContext = (updateObj) => {
    setCheckinContext({ ...checkinContext, ...updateObj });
    if (checkinContextOnHi) {
      Object.keys(updateObj).find((key) => {
        if (checkinContextOnHi[key] !== updateObj[key]) {
          setChangeInContext(true);
        }
      });
    }
  };

  const getRedux = async (studyID) => {
    const redux = await getReduxFunction(studyID);
    if (!checkinContextOnHi) {
      setCheckinContextOnHi(redux);
    }
    updateCheckinContext(redux);
  };

  const submitSafetyQuestions = (studyID, onError) => {
    Object.keys(checkinContext).forEach((field) => {
      const fieldValue = checkinContext[field];
      const replacedField =
        field === 'ReasonType' || field === 'ReasonForVisit'
          ? `Safety.PatientData.${field}`
          : `Safety.SafetyQuestions.${field}`;
      if (replacedField) {
        checkinContext[replacedField] = fieldValue;
        delete checkinContext[field];
      }
    });
    if ('Safety.SafetyQuestions.Prescription.PatientHealthNumber' in checkinContext) {
      const patient = piiContext.patient;
      if(patient){
        const phn = checkinContext['Safety.SafetyQuestions.Prescription.PatientHealthNumber'];
        fetchPiiResource(`/api/v1/userV2/${patient}`, 'PATCH', {
          phn
        }).catch((err) => {
          if (onError) {
            onError(err);
          }
          throw err;
        });
      }
    }
    if ("Safety.SafetyQuestions.Claustrophobic.OralSedativePrescription" in checkinContext ||
    "Safety.SafetyQuestions.Claustrophobic.Status" in checkinContext) {
      
      const oralSedativePrescription = checkinContext["Safety.SafetyQuestions.Claustrophobic.OralSedativePrescription"] || 'no';
      const sedative = oralSedativePrescription === 'yes'
      ? 'Pending'
      : 'Not Requested';
      
      fetchAppsResource(`/study/${studyID}/prescriptionStatus`, 'POST', {
        sedative
      }).catch((err) => {
        if (onError) {
          onError(err);
        }
        throw err;
      });
    }
    return fetchHiResource('/api/booking/safety-questions', 'POST', {
      ...checkinContext,
      studyID,
    }).catch((err) => {
      if (onError) {
        onError(err);
      }
      throw err;
    });
  };

  return (
    <CheckinContext.Provider
      value={{
        checkinContext,
        updateCheckinContext,
        getRedux,
        changeInContext,
        requiredFields,
        setRequiredFields,
        submitSafetyQuestions,
      }}
    >
      {children}
    </CheckinContext.Provider>
  );
};
