import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import {
  CalendarFooter,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableCell,
  TableHeader,
  YearMonthDiv,
} from '../calendarParts';

import { getAvailableTimes as getAvailableSlots, getFutureMomentObj } from '../calendarFunctions';
import { ManualTime, ManualTimeToggle } from './ManualTime';
import { MonthSelect } from '../MonthSelect';
import { SlotsForDay } from '../SlotsForDay';
import { StudyContext } from 'packages/booking-contexts';
import { Text } from 'shared-library';
import { TimezoneIndicator } from '../TimezoneIndicator';
import { WeekOfHeader } from './WeekOfHeader';
import { YearSelect } from '../YearSelect';

function getAllMachineSkus(location) {
  if (!location || !Array.isArray(location.machines)) {
    return [];
  }
  // Combine all the "skus" arrays from each machine into one flat array.
  return location.machines.flatMap(machine => machine.availableSKUs || []);
}

const Calendar = ({ skuID, children }) => {
  const { selectedLocation, updateStudyContext, memberType, getGeneralBookingInformation } = useContext(StudyContext);
  const { locationID } = selectedLocation;
  const machinesSkus = getAllMachineSkus(selectedLocation);

  const [availableTimes, setAvailableTimes] = useState({});
  const [dateHeaders, setDateHeaders] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);

  const currentMonth = moment().month() + 1;
  const currentYear = moment().year();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [manualTimeShow, setManualTimeShow] = useState(false);
  const [manualTimeClear, setManualTimeClear] = useState(false);

  const handleManualTimeShow = useCallback(() => {
    setManualTimeShow((manualTimeShow) => !manualTimeShow);
    moment.tz.setDefault(selectedLocation.timezone);
  }, []);

  const setSelectedTime = (time, id) => {
    updateStudyContext({ appointmentTime: time, machineId: id });
  };

  const handleMonthYearChange = (value, monthOrYear) => {
    const month = monthOrYear === 'year' ? selectedMonth - 1 : value - 1;
    const year = monthOrYear === 'year' ? value : selectedYear;

    const momentObj = getFutureMomentObj(month, year);

    if (monthOrYear === 'year') {
      setSelectedYear(value);
    } else {
      setSelectedMonth(value);
    }

    if (moment().diff(momentObj, 'days') <= 0) {
      setSelectedWeek(momentObj.unix());
    }
  };

  const getAvailableTimes = async () => {
    if (locationID) {
      const { data: allAvailableTimes } = await getAvailableSlots(locationID, skuID, selectedWeek, null, memberType);

      setAvailableTimes(allAvailableTimes);

      const allDateHeaders = Object.keys(allAvailableTimes).map((fullDate) => {
        const { dayOfWeek, day, month, arrow } = allAvailableTimes[fullDate];

        return {
          fullDate,
          dayOfWeek,
          day,
          month,
          unixTimestamp: arrow,
        };
      });

      setDateHeaders(allDateHeaders);

      return allDateHeaders[0].unixTimestamp;
    }
  };

  useEffect(() => {
    const getAvailableTimesForTheFirstTime = async () => {
      const firstSelectedWeek = await getAvailableTimes();
      setSelectedWeek(firstSelectedWeek);
    };
    getAvailableTimesForTheFirstTime();
  }, []);

  useEffect(() => {
    if (locationID && selectedWeek && skuID && memberType && machinesSkus.includes(skuID)) {
      getAvailableTimes();
      getGeneralBookingInformation(skuID);
    }
  }, [locationID, selectedWeek, skuID, memberType]);

  return (
    <>
      {locationID && skuID && machinesSkus.includes(skuID) && (
        <>
          <Text weight="600">Select Date & Time</Text>

          <YearMonthDiv>
            <MonthSelect
              handleMonthChange={({ value }) => handleMonthYearChange(value, 'month')}
              label="Month"
              monthValue={selectedMonth}
            />

            <YearSelect
              handleYearChange={({ value }) => handleMonthYearChange(value, 'year')}
              label="Year"
              yearRange={[currentYear, currentYear + 2]}
              yearValue={selectedYear}
            />

            {children}
          </YearMonthDiv>

          <WeekOfHeader
            selectedMonth={selectedMonth}
            selectedWeek={selectedWeek}
            selectedYear={selectedYear}
            setSelectedWeek={setSelectedWeek}
          />

          <Table>
            <TableHead>
              <TableHeadRow>
                {dateHeaders.map((date) => (
                  <TableHeader
                    current={moment(date.unixTimestamp * 1000).isSame(moment(), 'day')}
                    key={`header-${date.unixTimestamp}`}
                  >
                    {date.dayOfWeek}, {date.month} {date.day}
                  </TableHeader>
                ))}
              </TableHeadRow>
            </TableHead>

            <TableBody>
              <TableBodyRow>
                {dateHeaders.map((date) => {
                  const slots = availableTimes[date.fullDate]?.slots;

                  return (
                    <TableCell key={`table-cell-${date.unixTimestamp}`}>
                      <SlotsForDay
                        admin
                        key={`slot-${date.unixTimestamp}`}
                        setManualTimeClear={setManualTimeClear}
                        setSelectedTime={setSelectedTime}
                        slots={slots}
                      />
                    </TableCell>
                  );
                })}
              </TableBodyRow>
            </TableBody>
          </Table>

          <CalendarFooter>
            <TimezoneIndicator />

            <ManualTimeToggle handleManualTimeShow={handleManualTimeShow} />
          </CalendarFooter>

          {manualTimeShow && (
            <ManualTime
              manualTimeClear={manualTimeClear}
              setManualTimeClear={setManualTimeClear}
              setSelectedTime={setSelectedTime}
              skuID={skuID}
            />
          )}
        </>
      )}
    </>
  );
};

Calendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  skuID: PropTypes.string,
};

Calendar.defaultProps = {
  children: null,
  skuID: '',
};

export default Calendar;
