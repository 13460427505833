import React, { useState } from 'react';

export const AlertContext = React.createContext();

export const AlertContextProvider = ({ children }) => {
  const [alertModal, setAlertModal] = useState({});
  return (
    <AlertContext.Provider value={{ alertModal, setAlertModal }}>{children}</AlertContext.Provider>
  );
};
