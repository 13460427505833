import React, { useState, useEffect, useContext } from 'react';
import { fetchPiiResource } from 'packages/apis';
import {
  fetchSfResources,
  formatSfResourceIntoPiiContext,
  fetchSfOpportunity,
  formatSfBirthdate
} from 'packages/sales-force';
import { useRouteMatch, useLocation } from 'react-router';
import { StudyContext } from './StudyContext';
import { AlertContext } from './AlertContext';
import { BookingContext } from './BookingContext';
import { SALESFORCE_ERROR_ALERT } from 'packages/sales-force/constants';

export const PiiContextShape = {
  patient: '',
  firstname: '',
  middlename: '',
  preferredName: '',
  lastname: '',
  phn: '',
  email: '',
  yob: '',
  mob: '',
  dob: '',
  gender: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  phone_number: '',
  country: '',
  users: [],
  salesforceAccountId: null,
  sfOpportunityID: null,
  leadSource: '',
  leadSourceDetail: ''
};

export const PiiContext = React.createContext({
  piiContext: {},
  changePiiContext: () => {},
  getPii: () => {},
  assembleDOB: () => {},
  validateBeforeCreation: () => {},
  createPrenuvoUser: () => {},
  searchForExistingPrenuvoUser: () => {},
  updatePrenuvoUser: () => {},
  assemblePii: () => {},
  updateSfPerson: () => {},
  applyPiiContextAndUpdate: () => {},
  clearFieldsExceptEmail: () => {},
  resetPiiContext: () => {},
});

export const PiiContextProvider = ({ children }) => {
  const { prenuvoId, opportunityId, setOpportunityId } = useContext(StudyContext);
  const patient = prenuvoId;
  const [piiContext, setPiiContext] = useState(PiiContextShape);
  const [isFetchingOpportunity, setIsFetchingOpportunity] = useState(false);
  const { setAlertModal } = useContext(AlertContext);
  const { isTemporaryHold } = useContext(BookingContext);

  const { search } = useLocation();

  const {
    firstname,
    middlename,
    preferredName,
    lastname,
    phn,
    email,
    yob,
    mob,
    dob,
    gender,
    address,
    city,
    state,
    postalCode,
    // eslint-disable-next-line camelcase
    phone_number,
    country,
    leadSource,
    leadSourceDetail,
  } = piiContext;

  const changePiiContext = (e) => {
    if (e.target) {
      const key = e.target.id ? e.target.id : e.target.name;
      const { value } = e.target;
      if (key === 'birthdate') {
        const dobs = value.split('-');
        if (dobs.length === 3 && dobs[2] !== '') {
          const newArgs = {
            birthdate: value,
            yob: parseInt(dobs[0], 10),
            mob: parseInt(dobs[1], 10),
            dob: parseInt(dobs[2], 10),
          };
          return setPiiContext({ ...piiContext, ...newArgs });
        }
        return setPiiContext({ ...piiContext, birthdate: value });
      }
      return setPiiContext({
        ...piiContext,
        [key]: value,
      });
    }
    return setPiiContext({
      ...piiContext,
      ...e,
    });
  };
  const assembleDOB = () => {
    let birthdate = '';
    if (!(yob && mob && dob)) {
      return birthdate;
    }
    if (yob < 10) {
      birthdate += `0${yob}-`;
    } else {
      birthdate += `${yob}-`;
    }
    if (mob < 10) {
      birthdate += `0${mob}-`;
    } else if (mob > 12) {
      return 'month';
    } else {
      birthdate += `${mob}-`;
    }
    if (dob < 10) {
      birthdate += `0${dob}`;
    } else if (dob > 31) {
      return 'date';
    } else {
      birthdate += dob;
    }
    return birthdate;
  };

  const assemblePii = (d) => {
    const returnObj = {};
    const setPiiIfNotFalsy = (key) => {
      returnObj[key] = d[key];
    };
    if (d.prenuvoID) {
      returnObj.patient = d.prenuvoID;
    }
    [
      'firstname',
      'middlename',
      'preferredName',
      'lastname',
      'phn',
      'email',
      'gender',
      'address',
      'city',
      'state',
      'postalCode',
      'phone_number',
      'country',
      'salesforceAccountId',
      'leadSource',
      'leadSourceDetail',
    ].map((key) => setPiiIfNotFalsy(key));
    if (d.dob) {
      if (typeof d.dob !== 'number' && d.dob.includes('-')) {
        const birthdate = d.dob ? d.dob.split('-') : ['', '', ''];
        returnObj.yob = parseInt(birthdate[0]);
        returnObj.mob = parseInt(birthdate[1]);
        returnObj.dob = parseInt(birthdate[2]);
      } else {
        returnObj.yob = d.yob;
        returnObj.mob = d.mob;
        returnObj.dob = d.dob;
      }
    }
    return returnObj;
  };

  const validateBeforeCreation = () => {
    let alertText;
    const concatenateAlertText = (field) => {
      if (!alertText) {
        alertText = `Please Enter ${field}`;
      } else {
        alertText += `, ${field}`;
      }
    };
    if (!firstname) {
      concatenateAlertText('First Name');
    }
    if (!lastname) {
      concatenateAlertText('Last Name');
    }
    if (!email) {
      concatenateAlertText('Email');
    }
    if (!(yob && mob && dob)) {
      concatenateAlertText('Valid Date of Birth');
    }
    if (!gender) {
      concatenateAlertText('Biological Sex');
    }
    if (!(address && city && state && country)) {
      concatenateAlertText('Address');
    }
    if (!phone_number) {
      concatenateAlertText('Phone Number');
    }
    if (alertText) {
      return {
        alertModal: {
          title: alertText,
          text: '',
        },
      };
    }
    return { alertModal: null };
  };

  const createSfPersonAndOpportunity = (whitelabel, studyID) => {
    const params = new URLSearchParams(search);
    const personID = params.get('personID');

    if (!whitelabel) {
      fetchPiiResource(`/api/v1/salesforce/lead/prenuvoID/${patient}`, 'POST', {
        studyID,
        leadSource: piiContext.leadSource,
        leadSourceDetail: piiContext.leadSourceDetail,
        booking: true,
        personID,
        temporaryHold: isTemporaryHold
      });
    }
  };

  const createPrenuvoUser = async (locationId) => {
    try {
      const birthdate = assembleDOB();
      const params = new URLSearchParams(search);
      const salesforceAccountId = params.get('personID');
      const salesforceopportunityID = params.get('opportunityID');
      const accountObj = {
        firstname,
        middlename,
        preferredName,
        lastname,
        fullname: `${firstname} ${lastname}`,
        phn,
        email,
        dob: birthdate,
        gender,
        address,
        city,
        state,
        postalCode,
        phone_number,
        country,
        roles: ['user'],
        salesforceAccountId,
        salesforceopportunityID,
        leadSource,
        leadSourceDetail,
        location: [locationId]
      };
      const { parsedBody: createdAccount } = await fetchPiiResource(
        `/api/v1/userV2/`,
        'POST',
        accountObj,
      );
      const { user_id: prenuvoID, sf_account_id: sfAccountID } = createdAccount;
      if (prenuvoID) {
        changePiiContext({ patient: prenuvoID });
        return { patient: prenuvoID, sf_account_id: sfAccountID };
      }
    } catch (e) {
      return;
    }
  };
  const searchForExistingPrenuvoUser = async () => {
    const userDetails = {
      email,
      'exactMatch': true
    };
    const { parsedBody: foundUsers } = await fetchPiiResource(
      '/api/v1/userV2/search',
      'POST',
      userDetails,
    );
    return foundUsers.map((user) => ({ ...user, prenuvoID: user.user_id }));
  };
  const updatePrenuvoUser = async (queryObj = 'prenuvoID', prenuvoID) => {
    const params = new URLSearchParams(search);
    const salesforceopportunityID = params.get('opportunityID');

    validateBeforeCreation();
    const birthdate = assembleDOB();
    if (!birthdate.includes('-')) {
      return {
        alertModal: {
          title: 'Please Enter a Valid Date of Birth',
          msg: birthdate || '',
        },
      };
    }

    const updateObj = {
      firstname,
      middlename,
      preferredName,
      lastname,
      phn,
      dob: birthdate,
      gender,
      address,
      city,
      state,
      postalCode,
      phone_number,
      country,
      sfOpportunityID: salesforceopportunityID,
      leadSource,
      leadSourceDetail
    };
    const updatePii = async (url, updatedPii) => {
      try {
        const response = await fetchPiiResource(
          url,
          queryObj === 'email' ? 'PUT' : 'PATCH',
          updatedPii,
        );
        const { parsedBody } = response;
        await changePiiContext({ patient: parsedBody.prenuvoID, users: [] });
        return response;
      } catch (error) {
        return {
          alertModal: {
            title: 'Update Failed',
            msg: error,
          },
        };
      }
    };
    const url =
      queryObj === 'prenuvoID' && (patient || prenuvoID)
        ? `/api/v1/userV2/${patient || prenuvoID}`
        : '/api/v1/users/putUser';
    const updatedPii =
      queryObj === 'email' ? { updateObj, queryObj: { email } } : { ...updateObj, email };
    return updatePii(url, updatedPii);
  };
  const updateSfPerson = async (newlyUpdatedPiiContext) => {
    const prenuvoID = patient || newlyUpdatedPiiContext?.patient;
    const salesforceAccountId =
      piiContext.salesforceAccountId || newlyUpdatedPiiContext.salesforceAccountId;
    const salesforceObj = {
      prenuvoID,
      email,
      MiddleName: middlename,
      PersonMailingStreet: address,
      PersonMailingCity: city,
      PersonMailingState: state,
      PersonMailingPostalCode: postalCode,
      PersonMailingCountry: country,
    };
    if (prenuvoID) {
      salesforceObj.prenuvoID = prenuvoID;
    }
    if (salesforceAccountId) {
      await fetchPiiResource(
        `/api/v1/salesforce/person/${salesforceAccountId}`,
        'PATCH',
        salesforceObj,
      );
    }
  };

  const getPii = async (prenuvoID) => {
    if (prenuvoID) {
      const { parsedBody } = await fetchPiiResource(`/api/v1/userV2/${prenuvoID}`);
      const pii = assemblePii(parsedBody);
      pii.patient = prenuvoID;
      await changePiiContext(pii);
    }
  };

  const applyPiiContextAndUpdate = (newlyEnteredPii, updatePrenuvo, callback) => {
    let piiData = {};
    if (piiContext?.users?.length) {
      piiData = piiContext.users[0];
    } else if (piiContext?.salesforceAccountId) {
      piiData = piiContext;
    }

    const { prenuvoID, salesforceAccountId } = piiData;

    let updatedPiiData = newlyEnteredPii;
    if (piiData && !newlyEnteredPii) {
      updatedPiiData = piiData;
    }

    const piiObj = assemblePii({ ...updatedPiiData, prenuvoID, salesforceAccountId });
    piiObj.users = [];
    const promise = Promise.resolve(changePiiContext(piiObj));
    promise.then(async () => {
      if (updatePrenuvo) {
        const response = await updatePrenuvoUser('prenuvoID', prenuvoID);
        callback(response);
        if (response?.alertModal) {
          return response;
        }
      } else {
        callback({});
      }
      await updateSfPerson(piiObj);
    });
  };

  const reloadPage = () => {
    window.location.reload();
  }

  const { path } = useRouteMatch();

  useEffect(() => {
    if (
      (path === '/admin/booking/study/:studyId' ||
        path === '/admin/frontdesk/study/:studyId' ||
        path === '/booking/:page/:studyId' ||
        path === '/admin/study/:studyId/payment') &&
      prenuvoId
    ) {
      getPii(prenuvoId);
    }
  }, [prenuvoId]);

  useEffect(() => {
    const fetchOpportunity = async () => {
      setIsFetchingOpportunity(true);
      await fetchSfOpportunity({
        opportunityId,
        onFail: () => setAlertModal(SALESFORCE_ERROR_ALERT),
      }).then((data) => {
        if (data) {
          setPiiContext({
            ...piiContext,
            leadSource: data.LeadSource,
            leadSourceDetail: data.Lead_Source_Detail__c,
          });
        }
        setIsFetchingOpportunity(false);
      });
    };
    if (opportunityId && piiContext.firstname && !isFetchingOpportunity) {
      fetchOpportunity();
    }
  }, [opportunityId, piiContext.firstname]);

  useEffect(() => {
    if (path === '/admin/booking/salesforce') {
      const params = new URLSearchParams(search);
      const personId = params.get('personID');
      const opportunityId = params.get('opportunityID');

      if (opportunityId) {
        setOpportunityId(opportunityId);
      }

      const fetchSfAndSetPiiContext = async () => {
        const salesforceBody = await fetchSfResources({
          personId,
          opportunityId,
          onFail: () => reloadPage(),
        });

        let birthdate = '';
        if (salesforceBody?.person?.PersonBirthdate) {
          birthdate = formatSfBirthdate(salesforceBody?.person?.PersonBirthdate);
        }

        const newContext = formatSfResourceIntoPiiContext({
          patient,
          salesforceBody,
          personId,
          opportunityId,
          country,
          birthdate,
        });
        changePiiContext(newContext);
      };
      fetchSfAndSetPiiContext();
    }
  }, []);

  const clearFieldsExceptEmail = () => {
    setPiiContext((prevPiiContext) => ({
      ...PiiContextShape,
      email: prevPiiContext.email,
    }));
  };

  const resetPiiContext = () => {
    setPiiContext(PiiContextShape);
  };

  return (
    <PiiContext.Provider
      value={{
        piiContext,
        changePiiContext,
        assembleDOB,
        validateBeforeCreation,
        createPrenuvoUser,
        searchForExistingPrenuvoUser,
        updatePrenuvoUser,
        updateSfPerson,
        getPii,
        assemblePii,
        applyPiiContextAndUpdate,
        createSfPersonAndOpportunity,
        clearFieldsExceptEmail,
        resetPiiContext,
      }}
    >
      {children}
    </PiiContext.Provider>
  );
};
