import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'shared-library';

const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

export const getYearOptions = (yearRange) => {
  const years = [];
  range(yearRange[0], yearRange[1]).map((x) => years.push({ value: x, label: x }));
  return years;
};

export const YearSelect = ({
  yearValue,
  handleYearChange,
  placeholder,
  label,
  yearRange,
  reverse,
  className,
}) => {
  const [yearSelect, setYearSelect] = useState([]);
  useEffect(() => {
    const years = getYearOptions(yearRange);
    if (reverse) {
      years.reverse();
    }
    setYearSelect(years);
  }, []);
  return (
    <SelectInput
      width="300px"
      required
      options={yearSelect}
      value={yearSelect.filter((s) => s.value === yearValue)}
      onChange={handleYearChange}
      placeholder={placeholder}
      label={label}
      className={className}
      variant="primary"
    />
  );
};

YearSelect.propTypes = {
  yearValue: PropTypes.number,
  handleYearChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  yearRange: PropTypes.arrayOf(PropTypes.number),
  reverse: PropTypes.bool,
};

YearSelect.defaultProps = {
  yearValue: null,
  placeholder: '',
  label: '',
  yearRange: [0, 0],
  reverse: false,
};
