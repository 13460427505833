import { Text, RadioGroup, colors } from 'shared-library';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from './Layout';
import { CheckinContext } from '../CheckinContext';

interface RadioQuestionProps {
  title: string;
  options: Array<any>;
  handleCheck: (checkValue: any) => void;
  parent: string;
  show: string;
  value: string;
}

const StyledRadios = styled(RadioGroup.Input)<{ highlighted: boolean }>`
  flex: 1;

  ${({ highlighted }) =>
    highlighted &&
    `
      &::before {
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        height: 17px;
        left: -9px;
        top: 0;
        width: 17px;
        box-shadow: 0 0 10px ${colors.secondary};
      }
  `}

  label {
    margin-bottom: 0;
  }
`;

const QuestionLabel = styled(Text)`
  padding-left: 20px;
`;

const getGridColumns = (columns: number) => {
  if (columns === 2) {
    return '70% repeat(1, 30%);';
  }
  if (columns === 3) {
    return '56% repeat(1,44%);';
  }
  if (columns === 0) {
    return '100%; row-gap: 0;';
  }
  return `${100 - 17 * columns}% repeat(${columns}, 17%)`;
};

const QuestionLayoutRow = styled.div<{ columns?: number; margin?: string }>`
  display: grid;
  row-gap: 20px;
  padding: 20px 0;
  align-items: center;

  > p {
    word-wrap: break-word;
  }

  ${({ columns }) => (columns ? `grid-template-columns: ${getGridColumns(columns)}` : '')};
  ${({ margin }) => margin && `margin: ${margin}`};
`;

const RadioQuestion: React.FC<RadioQuestionProps> = ({
  title,
  options,
  handleCheck,
  parent,
  show,
  value,
}: RadioQuestionProps) => {
  const splitTitle = title.split('<u>');
  const formattedTitle =
    splitTitle.length === 3
      ? [splitTitle[0], <u key={splitTitle[1]}>{splitTitle[1]}</u>, splitTitle[2]]
      : title;
  const threeRadioQuestion = options.length === 3 && parent !== 'ReasonType';
  const { requiredFields, checkinContext } = useContext(CheckinContext);
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    const checkHighlighted = requiredFields.includes(parent) && !checkinContext[parent];
    if (highlighted !== checkHighlighted) {
      setHighlighted(checkHighlighted);
    }
  }, [checkinContext, requiredFields]);

  return (
    <>
      <QuestionLayoutRow columns={threeRadioQuestion ? 3 : parent === 'ReasonType' ? 0 : 2}>
        <Column
          margin={
            show !== 'always' && parent !== 'Female.Pregnancy.Status' ? '1em 0 1em 2em' : '0px'
          }
        >
          <QuestionLabel noMargin>{formattedTitle}</QuestionLabel>
        </Column>
        <Column margin="0 10px">
          <RadioGroup
            name={parent}
            onChange={handleCheck}
            initialValue={`${parent}.${value}`}
            position={parent === 'ReasonType' ? 'vertical' : 'horizontal'}
          >
            {options.map((s) => (
              <StyledRadios
                label={s.label}
                value={`${parent}.${s.value}`}
                id={`${parent}.${s.value}-radio-input`}
                key={`${parent}.${s.value}-radio-input`}
                highlighted={highlighted}
              />
            ))}
          </RadioGroup>
        </Column>
      </QuestionLayoutRow>
    </>
  );
};

export default RadioQuestion;
