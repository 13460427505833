import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { fetchPiiResource } from 'packages/apis';

export const BookingContext = React.createContext({});

export const BookingContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState('');
  const [allowedLocations, setAllowedLocations] = useState([]);
  const [skus, setSkus] = useState([]);
  const [locations, setLocations] = useState([]);
  const [stripeTokens, setStripeTokens] = useState([]);
  const [promocodes, setPromocodes] = useState([]);
  const [isTemporaryHold, setIsTemporaryHold] = useState(false);

  const updateBookingContext = (change) => {
    if (change.skus) {
      setSkus(change.skus);
    }

    if (change.locations) {
      setLocations(change.locations);
    }

    if (change.stripeTokens) {
      setStripeTokens(change.stripeTokens);
    }

    if (change.promocodes) {
      setPromocodes(change.promocodes);
    }

    if (change.isTemporaryHold != null) {
      setIsTemporaryHold(change.isTemporaryHold);
    }
  };

  const { path } = useRouteMatch();

  useEffect(() => {
    const getGeneralBookingInformation = async () => {
      if (path !== '/booking/slot') {
        const { parsedBody: current } = await fetchPiiResource(`/api/v1/userV2/current`);

        const { user } = current;

        setCurrentUser(user);
        setAllowedLocations(user.location);
      }
    };

    getGeneralBookingInformation();
  }, []);

  return (
    <BookingContext.Provider
      value={{
        locations,
        stripeTokens,
        skus,
        promocodes,
        currentUser,
        updateBookingContext,
        allowedLocations,
        isTemporaryHold,
        setIsTemporaryHold,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
