import { CheckboxInput, Label } from 'shared-library';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckinContext } from '../CheckinContext';
import { Column, QuestionLayoutRow } from './Layout';

const CheckboxRow = styled(QuestionLayoutRow)<{ options: number }>`
  margin: 1em 2em;
  grid-template-columns: repeat(${({ options }) => `${options}, ${100 / options}%`});
`;

interface CheckboxQuestionProps {
  title: any;
  options: any;
  handleCheck: any;
  parent: any;
  show: any;
  value: any;
}

const CheckboxQuestion: React.FC<CheckboxQuestionProps> = ({
  title,
  options,
  handleCheck,
  parent,
  show,
  value,
}: CheckboxQuestionProps) => {
  const { requiredFields, checkinContext } = useContext(CheckinContext);
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    const checkHighlighted =
      requiredFields.includes(parent) &&
      (!checkinContext[parent] || checkinContext[parent].length === 0);
    if (highlighted !== checkHighlighted) {
      setHighlighted(checkHighlighted);
    }
  }, [checkinContext, requiredFields]);

  const splitTitle = title.split('<u>');
  const formattedTitle =
    splitTitle.length === 3 ? [splitTitle[0], <u>{splitTitle[1]}</u>, splitTitle[2]] : title;
  return (
    <>
      <QuestionLayoutRow>
        <Column margin={show !== 'always' && '1em 2em'}>
          <Label>{formattedTitle}</Label>
        </Column>
      </QuestionLayoutRow>
      <CheckboxRow options={options.length}>
        {options.map((s) => (
          <Column key={`${parent}.${s.value}-col`}>
            <CheckboxInput
              label={s.label}
              value={`${parent}.${s.value}`}
              id={`${parent}.${s.value}`}
              onChange={() => handleCheck(`${parent}.${s.value}`)}
              checked={value && value.includes(s.value)}
              key={`${parent}.${s.value}`}
              highlighted={highlighted}
            />
          </Column>
        ))}
      </CheckboxRow>
    </>
  );
};

export default CheckboxQuestion;
