import { fetchPiiResource } from 'packages/apis';

export interface Opportunity {
  studyID: string;
}

export const mapStudyToOpportunity = (study: any): Opportunity => {
  return {
    studyID: study?.studyID,
  };
};

interface updateSfOpportunityProps {
  study: any;
  updateStudy?: boolean;
  newOpportunity?: boolean;
  onFail?: () => void;
  onSuccess?: (studyId?: string, opportunityId?: string) => void;
  extraFields?: any;
}

interface SalesForceOpportunity {
  opportunityId?: string;
  opportunityID?: string;
}

export const updateSfOpportunity = async ({
  study,
  updateStudy,
  onFail,
  onSuccess,
  newOpportunity = true,
  extraFields,
}: updateSfOpportunityProps): Promise<void> => {
  const isPatch = study.sfOpportunityID && !newOpportunity;
  const url = isPatch
    ? `/api/v1/salesforce/opportunity/${study.sfOpportunityID}`
    : `/api/v1/salesforce/opportunity/prenuvoID/${study?.prenuvoId || study.patient}`;

  try {
    const { parsedBody } = await fetchPiiResource<SalesForceOpportunity>(
      url,
      isPatch ? 'PATCH' : 'POST',
      {
        studyID: study?.studyID,
        study,
        updateStudy: updateStudy !== false,
        newOpportunity: newOpportunity && !study.opportunityID,
        ...(newOpportunity && !study.opportunityID && { Booking_Method__c: 'Sales Booked' }),
        ...extraFields,
      },
    );
    if (onSuccess) {
      onSuccess(study.studyID, parsedBody?.opportunityId || parsedBody?.opportunityID);
    }
  } catch {
    if (onFail) {
      onFail();
    }
  }
};

export const fetchSfResources = async ({ personId, opportunityId, onFail }) => {
  const url = `/api/v1/salesforce/booking/person/${personId}/opportunity/${opportunityId}`;

  const sfResources = await fetchPiiResource(url, 'GET')
    .then((res) => {
      return res.parsedBody;
    })
    .catch(() => {
      if (onFail) {
        onFail();
      }
    });

  return sfResources;
};

export const fetchSfOpportunity = async ({ opportunityId, onFail }) => {
  const url = `/api/v1/salesforce/opportunity/${opportunityId}`;

  const sfOpportunity = await fetchPiiResource(url, 'GET')
    .then((res) => {
      return res.parsedBody;
    })
    .catch(() => {
      if (onFail) {
        onFail();
      }
    });

  return sfOpportunity;
};
