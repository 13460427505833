import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation, useRouteMatch } from 'react-router';

import { fetchAppsResource, fetchLegacyResource, fetchPiiResource } from 'packages/apis';
import { updateSfOpportunity as updateSfOpportunityPkg } from 'packages/sales-force';

import { BookingContext } from './BookingContext';
import { LoadingContext } from './LoadingContext';
import { PiiContext } from './PiiContext';

export const StudyContext = React.createContext({});

// This is necessary because salesforce only have the names of the locations.
// When we add a new location we have to update this list
const LOCATION_MAPPING = {
  'Atlanta, GA': 'usa-ga-001',
  'Boca Raton, FL': 'usa-fl-001',
  'Boston, MA - Clinical trial': 'usa-ma-001',
  'Chicago, IL': 'usa-il-001',
  'Dallas, TX': 'usa-tx-001',
  'Los Angeles, CA': 'usa-ca-004',
  'Minneapolis, MN': 'usa-mn-001',
  'New York, NY': 'usa-ny-001',
  'Silicon Valley, CA': 'usa-ca-001',
  'Vancouver, BC, Canada': 'can-bc-001',
  'Vancouver (Arc), BC, Canada': 'can-bc-002',
  'Washington, DC': 'usa-md-001',
  'Houston, TX': 'usa-tx-002',
  'Buffalo, NY': 'usa-ny-002',
  'Minneapolis (SLP), MN': 'usa-mn-002',
  'San Diego, CA': 'usa-ca-005',
  'Denver, CO': 'usa-co-001',
  'Seattle, WA': 'usa-wa-001',
  'Scottsdale, AZ': 'usa-az-001',
  'Las Vegas, NV': 'usa-nv-001',
  'Melbourne, VI, Australia': 'aus-vi-001',
  'Irvine, CA': 'usa-ca-006',
  'Pasadena, CA': 'usa-ca-007',
};

export const MEMBER_TYPE_OPTIONS = [
  'New Member',
  'Priority Access',
  'Returning Member / Partner Referral'
]

export const StudyContextProvider = ({ children }) => {
  const { path } = useRouteMatch();

  let setLoading;

  if (path.includes('/admin/booking')) {
    const loadingContext = useContext(LoadingContext);

    setLoading = loadingContext.setLoading;
  }

  const { skus, locations, updateBookingContext, isTemporaryHold, setIsTemporaryHold } = useContext(
    BookingContext,
  );

  const { studyId } = useParams();
  const { search } = useLocation();

  const [isUpdate, setIsUpdate] = useState(!path.includes('/booking'));
  const [proactiveBooking, setProactiveBooking] = useState(false);
  const [prenuvoId, setPrenuvoId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [skuID, setSkuID] = useState('300');
  const [machineID, setMachineID] = useState('');
  const [skuDetails, setSkuDetails] = useState({ name: '', promocodes: [], skuID: '', price: 0 });
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [booking, setBooking] = useState({});
  const [items, setItems] = useState({});
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [referralType, setReferralType] = useState('prenuvo');
  const [doctors, setDoctors] = useState([]);
  const [referrer, setReferrer] = useState(null);
  const [status, setStatus] = useState({});
  const [customerEmailAvailable, setCustomerEmailAvailable] = useState(true);
  const [invoiceAvailable, setInvoiceAvailable] = useState(true);
  const [salesNotes, setSalesNotes] = useState('');
  const [opportunityId, setOpportunityId] = useState('');
  const [bookingPageType] = useState(path.includes('/admin') ? 'assisted' : 'selfService');
  const [calendarID, setCalendarID] = useState(null);
  const [eventID, setEventID] = useState(null);
  const [slotSelectionType, setSlotSelectionType] = useState('slot');
  const [isUpdateAppointment, setIsUpdateAppointment] = useState(false);
  const [showWhiteLabelToast, setShowWhiteLabelToast] = useState(false);
  const [memberType, setMemberType] = useState('New Member');

  const updateStudyContext = (updateObj) => {
    if (updateObj.prenuvoId && updateObj.prenuvoId !== prenuvoId) {
      setPrenuvoId(updateObj.prenuvoId);
    }

    if (updateObj.prenuvoId === null) {
      setPrenuvoId(null);
    }

    if (updateObj.selectedLocation) {
      setSelectedLocation(updateObj.selectedLocation);
    }

    if (updateObj.skuID && skuID !== updateObj.skuID) {
      setSkuID(updateObj.skuID);
    }

    if (updateObj.machineId && machineID !== updateObj.machineId) {
      setMachineID(updateObj.machineId);
    }

    if (updateObj.calendarID && calendarID !== updateObj.calendarID) {
      setCalendarID(updateObj.calendarID);
    }

    if (updateObj.eventID && eventID !== updateObj.eventID) {
      setEventID(updateObj.eventID);
    }

    if (updateObj.paymentAmount && paymentAmount !== updateObj.paymentAmount) {
      setPaymentAmount(updateObj.paymentAmount);
    }

    if (updateObj.booking) {
      setBooking(updateObj.booking);
    }

    if (updateObj.items) {
      setItems(updateObj.items);
    }

    if (typeof updateObj.appointmentTime !== 'undefined') {
      setAppointmentTime(updateObj.appointmentTime);

      if (updateObj.appointmentTime && booking?.utcStart && status.booked) {
        if (parseInt(booking?.utcStart) !== parseInt(updateObj.appointmentTime)) {
          setIsUpdate(true);
        }
      }
    }

    if (updateObj.status) {
      setStatus({ ...status, ...updateObj.status });
    }

    if (
      updateObj.customerEmailAvailable &&
      customerEmailAvailable !== updateObj.customerEmailAvailable
    ) {
      setCustomerEmailAvailable(updateObj.customerEmailAvailable);
    }

    if (
      typeof updateObj.invoiceAvailable === 'boolean' &&
      invoiceAvailable !== updateObj.invoiceAvailable
    ) {
      setInvoiceAvailable(updateObj.invoiceAvailable);
    }

    if (updateObj.referralType && referralType !== updateObj.referralType) {
      setReferralType(updateObj.referralType);
    }

    if (updateObj.referrer && referrer !== updateObj.referrer) {
      setReferrer(updateObj.referrer);
    }

    if (updateObj.doctors) {
      setDoctors(updateObj.doctors);
    }

    if (updateObj.slotSelectionType) {
      setSlotSelectionType(updateObj.slotSelectionType);
    }

    if (updateObj.memberType) {
      setMemberType(updateObj.memberType);
    }

    if (updateObj.temporaryHold) {
      setIsTemporaryHold(updateObj.temporaryHold);
    }
  };

  const getBookingFromParams = () => {
    const params = new URLSearchParams(search);
    const locationID = params.get('locationID');
    const skuId = params.get('skuID');

    setSkuID(skuId);

    return locationID;
  };

  const getGeneralBookingInformation = async (skuID = null) => {
    let locationId;

    if (path === '/booking/slot') {
      locationId = getBookingFromParams();
    }

    const url =
      path === '/booking/:page/:studyId' && selectedLocation?.locationID
        ? `/booking/?locationID=${selectedLocation.locationID}${skuID ? `&skuID=${skuID}` : ''}`
      : path === '/booking/slot' && locationId
        ? `/booking/?locationID=${locationId}${skuID ? `&skuID=${skuID}` : ''}`
      : skuID
        ? `/booking/?skuID=${skuID}`
        : '/booking/';

    const { parsedBody } = await fetchAppsResource(url);
    const { skus: allSkus, locations: allLocations, stripeTokens, promocodes } = parsedBody;
  
    updateBookingContext({
      skus: allSkus,
      locations: allLocations,
      stripeTokens,
      promocodes
    });
  };

  const updateStudyObj = async (update, callback) => {
    await fetchLegacyResource(`/study/${studyId}/updateObjRoot`, 'POST', update);

    if (callback) {
      callback();
    }
  };

  const updateStudyDetails = async (eventID, callback, newStudyId) => {
    await fetchAppsResource(`/study/${studyId || newStudyId}/detailed`, 'POST', {
      eventId: isTemporaryHold ? eventID : booking.eventID || eventID,
      salesNotes,
      referralType,
      referrer,
      doctors: referralType === 'doctor' ? [referrer, ...doctors] : doctors,
      isUpdate,
      oldUtcStart: booking.utcStart,
      opportunityID: opportunityId,
      bookingPageType,
    });

    if (callback) {
      callback();
    }
  };

  const addToGoogleCalendar = async (handleUpdateStudyDetails, accountData) => {
    const selectedMachine = selectedLocation?.machines.find(
      (machine) => machine.machineID === machineID,
    );

    let end = 0;

    if (skuID && selectedMachine?.defaultTimes && selectedMachine.defaultTimes[skuID]) {
      const defaultTime = selectedMachine.defaultTimes[skuID];

      if (defaultTime.clean) {
        end += defaultTime.clean;
      }

      if (defaultTime.scan) {
        end += defaultTime.scan;
      }

      if (defaultTime.block) {
        end += defaultTime.block;
      }

      end = end * 60 + parseInt(appointmentTime);
    } else {
      const primaryItem = selectedLocation.catalog.primary.find(
        (primary) => primary.skuID === skuID,
      );
      end = (primaryItem.cleaningTime + primaryItem.scanTime) * 60 + parseInt(appointmentTime);
    }

    const { timezone } = selectedLocation;

    const { parsedBody: eventID } = await fetchAppsResource('/calendar/event/add', 'POST', {
      calendarID: selectedMachine?.calendarID || selectedMachine?.calendar,
      start: appointmentTime,
      end,
      timezone,
      studyID: studyId,
      prenuvoID: prenuvoId,
      item: skuDetails.shortName,
      oldUtcStart: booking.utcStart,
      oldUtcEnd: booking.utcEnd,
      oldCalendarId: booking.calendarID,
      oldTimezone: booking.timezone,
      slotSelectionType,
      accountData,
    });

    if (eventID.status === 400) {
      setLoading(eventID.message);
      return;
    }

    updateStudyContext({ booking: { ...booking, eventID: eventID.id } });

    if (setLoading) {
      setLoading('Sending emails');
    }

    await updateStudyDetails(eventID.id, handleUpdateStudyDetails);
  };

  const addTemporaryHoldToGoogleCalendar = async (handleTemporaryHold, accountData, newStudyId) => {
    const selectedMachine = selectedLocation?.machines.find(
      (machine) => machine.machineID === machineID,
    );

    let end = 0;

    if (skuID && selectedMachine?.defaultTimes && selectedMachine.defaultTimes[skuID]) {
      const defaultTime = selectedMachine.defaultTimes[skuID];

      if (defaultTime.clean) {
        end += defaultTime.clean;
      }

      if (defaultTime.scan) {
        end += defaultTime.scan;
      }

      if (defaultTime.block) {
        end += defaultTime.block;
      }

      end = end * 60 + parseInt(appointmentTime);
    } else {
      const primaryItem = selectedLocation.catalog.primary.find(
        (primary) => primary.skuID === skuID,
      );
      end = (primaryItem.cleaningTime + primaryItem.scanTime) * 60 + parseInt(appointmentTime);
    }

    const { timezone } = selectedLocation;
    
    if (setLoading) {
      setLoading('Creating Temporary Hold Event');
    }

    const { parsedBody: eventID } = await fetchAppsResource('/calendar/event/add-temporary-hold', 'POST', {
      calendarID: selectedMachine?.calendarID || selectedMachine?.calendar,
      start: appointmentTime,
      end,
      timezone,
      studyID: newStudyId,
      prenuvoID: prenuvoId,
      item: skuDetails.shortName,
      oldUtcStart: booking.utcStart,
      oldUtcEnd: booking.utcEnd,
      oldCalendarId: booking.calendarID,
      oldTimezone: booking.timezone,
      slotSelectionType,
      accountData,
    });

    if (eventID.status === 400) {
      setLoading(eventID.message);
      return;
    }
    
    setEventID(eventID.id);
    updateStudyContext({ booking: { ...booking, eventID: eventID.id } });
    
    await updateStudyDetails(eventID.id, handleTemporaryHold, newStudyId);
   
    if (setLoading) {
      setLoading('');
    }
    
  };

  const updateAppointment = async () => {
    await fetchAppsResource('/booking/change/appointment-time', 'POST', {
      newUtcStart: appointmentTime,
      machineID,
      skuID,
      studyID: studyId,
      locationID: selectedLocation.locationID,
      slotSelectionType,
    });
  };

  const createStudy = async (handleMissingInformation, handleStudyCreation) => {
    if (!(appointmentTime && machineID)) {
      if (handleMissingInformation) {
        handleMissingInformation();
      }

      return;
    }

    if (isTemporaryHold && setLoading) {
      setLoading('Creating Temporary Hold Study');
    }

    const createBookingEndpoint = isTemporaryHold ? "/booking/create/temporary-hold" : "/booking/create"
    const { parsedBody } = await fetchAppsResource(createBookingEndpoint, 'POST', {
      studyID: studyId,
      opportunityId,
      skuID,
      datetime: appointmentTime,
      locationID: selectedLocation.locationID,
      machineID,
      patient: prenuvoId,
      memberType,
      slotSelectionType,
    });

    const { studyID: newStudyId } = parsedBody;

    if (handleStudyCreation) {
      handleStudyCreation(newStudyId);
    }

    if (selectedLocation.type === 'whitelabel') {
      await fetchAppsResource(`/payment/study/${newStudyId}/whitelabel`, 'POST');
      setShowWhiteLabelToast(true);
    }
  };

  const updateSfOpportunityFromStudy = async ({ onFail, onSuccess, extraFields }) => {
    const { parsedBody: data } = await fetchAppsResource(`/payment/study/${studyId}`);

    let composeItems = {
      referrer: '',
      discountAmount: 0,
      discountReferral: 0,
      promocode: {
        code: '',
        category: '',
        reason: ''
      }
    };

    if (data && (data?.discountPromo || data.transactions.length > 0)) {
      const referralCode = data.transactions.find(
        (_transaction) => _transaction.paymentType === 'referral code',
      );
      composeItems = {
        referrer: referralCode?.details?.referralCode || '',
        discountAmount: data?.discountPromo || 0,
        discountReferral: data?.discountReferral || 0,
        promocode: {
          code: data?.promo.code || '',
          category: data?.promo.category || '',
          reason: data?.promo.reason || '',
        },
      };
    }

    await updateSfOpportunityPkg({
      study: {
        studyID: studyId,
        location: selectedLocation,
        skuID,
        items: composeItems,
        opportunityID: opportunityId,
        prenuvoId,
        stage: status.booked,
        appointmentTime,
        isTemporaryHold,
      },
      onFail,
      onSuccess,
      extraFields
    });
  };

  const finalizeBooking = async (callback) => {
    const updateSalesforceTimeoutAndSendEmails = async () => {
      updateSfOpportunityFromStudy();

      if (callback) {
        callback();
      }
    };

    await addToGoogleCalendar(updateSalesforceTimeoutAndSendEmails);
  };

  const resetAppointmentTime = () => {
    setMachineID(null);
    setAppointmentTime(null);
  };

  useEffect(() => {
    getGeneralBookingInformation();
  }, []);

  useEffect(() => {
    if (locations.length === 0 && selectedLocation?.locationID) {
      getGeneralBookingInformation();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (
      typeof status.customerEmailAvailable !== 'undefined' &&
      status.customerEmailAvailable !== customerEmailAvailable
    ) {
      setCustomerEmailAvailable(status.customerEmailAvailable);
    }

    if (
      typeof status.invoiceAvailable !== 'undefined' &&
      status.invoiceAvailable !== invoiceAvailable
    ) {
      setInvoiceAvailable(status.invoiceAvailable);
    }
  }, [status]);

  useEffect(() => {
    const getStudy = async () => {
      const { parsedBody } = await fetchAppsResource(`/study/${studyId}?booking=true`);

      const {
        booking,
        location,
        patient,
        status,
        items,
        paymentAmount,
        practitioners,
        config,
        sfOpportunityID,
        memberType,
        temporaryHold
      } = parsedBody;

      setProactiveBooking(!!config?.proactiveBooking);

      setPaymentAmount(paymentAmount);

      if (booking.length) {
        setBooking({
          ...booking[0],
          timezone: location.timezone,
          displayTimezone: location.displayTimezone,
        });

        if (!appointmentTime) {
          setAppointmentTime(booking[0].utcStart);
        }

        if (!machineID) {
          setMachineID(booking[0].machineID);
        }
      }

      if (selectedLocation && !Object.keys(selectedLocation).length) {
        setSelectedLocation(location);
      }

      if (practitioners?.additional?.length > 0) {
        const practitionersLoaded = practitioners?.additional;
        if (practitioners?.primary) {
          practitionersLoaded.splice(0, 0, practitioners.primary);
        }
        setDoctors(practitionersLoaded);
      }

      if (practitioners?.referring) {
        setReferrer(practitioners.referring);
      }

      if (practitioners?.referralType) {
        setReferralType(practitioners?.referralType);
      }

      if (patient?.prenuvoID) {
        setPrenuvoId(patient.prenuvoID);
      }

      setStatus(status);
      setIsUpdate(status.booked);
      setItems(items);
      setSkuID(items.primary.skuID);
      setOpportunityId(sfOpportunityID)
      
      if (memberType) {
        setMemberType(memberType)
      }

      if (temporaryHold) {
        setIsTemporaryHold(temporaryHold)
      }
    };

    if (studyId) {
      getStudy();
    }
  }, [studyId]);

  useEffect(() => {
    if (skus.length && skuID) {
      const skuInformation = skus.find((sku) => sku.skuID === skuID);
      setSkuDetails(skuInformation);
    }
  }, [skus, skuID]);

  useEffect(() => {
    if (
      path === '/admin/booking' ||
      path === '/admin/booking/salesforce' ||
      path === '/booking/slot'
    ) {
      const params = new URLSearchParams(search);
      const location = params.get('location');
      let sku = params.get('sku');

      const urlParamLocation = locations.find(
        (_location) => _location.locationID === LOCATION_MAPPING[location],
      );
      if (urlParamLocation) {
        setSelectedLocation(urlParamLocation);
      }
      if (parseInt(sku, 10)) {
        const skuInformation = skus.find((_sku) => _sku.skuID === sku);
        if (skuInformation) {
          setSkuDetails(skuInformation);
          setSkuID(skuInformation.skuID);
        }
      } else {
        if (sku === 'VIP') sku = 'VIP Whole body';
        if (sku === 'Research & Development') sku = 'Research and Development';
        const skuInformation = skus.find((_sku) => _sku.shortName === sku);
        if (skuInformation) {
          setSkuDetails(skuInformation);
          setSkuID(skuInformation.skuID);
        }
      }
    }
  }, [locations]);

  return (
    <StudyContext.Provider
      value={{
        isUpdate,
        studyId,
        prenuvoId,
        selectedLocation,
        skuID,
        machineID,
        skuDetails,
        paymentAmount,
        booking,
        items,
        appointmentTime,
        status,
        customerEmailAvailable,
        invoiceAvailable,
        opportunityId,
        setOpportunityId,
        updateStudyContext,
        updateStudyObj,
        updateAppointment,
        createStudy,
        updateSfOpportunityFromStudy,
        salesNotes,
        setSalesNotes,
        referralType,
        referrer,
        doctors,
        setDoctors,
        finalizeBooking,
        addToGoogleCalendar,
        proactiveBooking,
        calendarID,
        eventID,
        slotSelectionType,
        resetAppointmentTime,
        isUpdateAppointment,
        setIsUpdateAppointment,
        showWhiteLabelToast,
        setShowWhiteLabelToast,
        memberType,
        setMemberType,
        addTemporaryHoldToGoogleCalendar,
        getGeneralBookingInformation
      }}
    >
      {children}
    </StudyContext.Provider>
  );
};
