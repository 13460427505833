import React from 'react';
import styled from 'styled-components';
import { SelectInput } from 'shared-library';

const StyledSelect = styled(SelectInput)`
  text-transform: none;
  display: block !important;
  margin: 0 !important;
  width: 300px !important;
`;

export const MonthSelect = ({ monthValue, handleMonthChange, placeholder, label, className }) => {
  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];
  return (
    <StyledSelect
      required
      options={months}
      value={months.filter((s) => s.value === monthValue)}
      onChange={handleMonthChange}
      placeholder={placeholder}
      label={label}
      className={className}
      variant="primary"
    />
  );
};
