type BirthdateType = {
  birthdate: string;
  yob: number;
  mob: number;
  dob: number;
};

export function formatSfResourceIntoPiiContext({
  patient,
  salesforceBody,
  personId,
  opportunityId,
  country,
  birthdate,
}) {
  const contextPayload = {
    patient,
    firstname: salesforceBody?.person?.FirstName || '',
    middlename: salesforceBody?.person?.MiddleName || '',
    lastname: salesforceBody?.person?.LastName || '',
    email: salesforceBody?.person?.PersonEmail || '',
    address: salesforceBody?.person?.PersonMailingStreet || '',
    city: salesforceBody?.person?.PersonMailingCity || '',
    state: salesforceBody?.person?.PersonMailingState || '',
    postalCode: salesforceBody?.person?.PersonMailingPostalCode || '',
    phone_number: salesforceBody?.person?.Phone || '',
    country: salesforceBody?.person?.PersonMailingCountry || '',
    salesforceAccountId: salesforceBody?.person?.Id || '',
    sfOpportunityID: salesforceBody?.opportunity?.Id || '',
    leadSource: salesforceBody?.opportunity?.LeadSource || '',
    leadSourceDetail: salesforceBody?.opportunity?.Lead_Source_Detail__c || '',
    gender: salesforceBody?.person?.Gender__c || '',
    yob: birthdate?.yob || '',
    mob: birthdate?.mob || '',
    dob: birthdate?.dob || '',
  };

  const newContext = {
    ...contextPayload,
    salesforceAccountId: personId,
    sfOpportunityID: opportunityId,
    patientType: 'CRM',
  };

  if (country === 'CA') {
    newContext.country = 'Canada';
  }

  return newContext;
}

export function formatSfBirthdate(birthdate: string): BirthdateType {
  const dobs = birthdate.split('-');

  if (dobs.length === 3 && dobs[2] !== '') {
    const newArgs = {
      birthdate,
      yob: parseInt(dobs[0], 10),
      mob: parseInt(dobs[1], 10),
      dob: parseInt(dobs[2], 10),
    };
    return newArgs;
  }

  return {
    birthdate,
    yob: null,
    mob: null,
    dob: null,
  };
}
