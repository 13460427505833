import { Button, colors, Modal, SelectInput, Text, TextAreaInput } from 'shared-library';
import { formatUnixDatetime } from 'packages/formatters';
import React from 'react';
import styled from 'styled-components';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import * as yup from 'yup';

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 32px;
`;

const StyledTextAreaInput = styled(TextAreaInput)`
  height: 116px;
`;

const QuestionContainer = styled.div`
  margin: 32px auto;
`;

const cancellationCopy = [
  'Financial - Cost',
  'Financial - Lack of Insurance Coverage',
  'Financial - Less Expensive Alternative',
  'Medical - Emergency',
  'Medical - Illness',
  'Medical - Not Correct Procedure',
  'Medical - Pregnancy',
  'Medical - Referral Denied',
  'Medical - Safety Reason',
  'Member - Agreement/MIF Aversion',
  'Member - Booking Error',
  'Member - Commute',
  'Member - Commute Issues',
  'Member - Family Emergency',
  'Member - Fear/Claustrophobia',
  'Member - Reconsidered - Media',
  "Member - Reconsidered - Physician's Opinion",
  'Member - Undisclosed',
  'Prenuvo - Booking Error',
  'Prenuvo - Double Booking',
  'Other',
];

const cancellationOptions = cancellationCopy.map((copy) => ({ label: copy, value: copy }));

const ErrorText = styled(Text).attrs({
  color: colors.error,
  weight: '600',
})`
  font-size: 12px;
`;

const reqErrMsg = 'This field is required';

const validationSchema = yup.object().shape({
  cancellationReason: yup
    .object()
    .shape({
      label: yup.string().required(reqErrMsg),
      value: yup.string().required(reqErrMsg),
    })
    .required(reqErrMsg)
    .typeError(reqErrMsg),
  cancellationDetail: yup.string().required(reqErrMsg).max(255, 'The max length is 255'),
});

export const CancellationModal = ({
  appointmentType,
  setRemoveModal,
  originalAppointmentTime,
  handleRemoveAppointment,
}) => {
  const { control, errors, handleSubmit } = useForm({ validationSchema });

  return (
    <Modal
      title="Are you sure you want to cancel the following appointment?"
      onClose={() => setRemoveModal(false)}
    >
      <Text align="center">Appointment Type: {appointmentType}</Text>
      <Text align="center">
        Appointment Date and Time: {formatUnixDatetime(originalAppointmentTime)}
      </Text>
      <form
        onSubmit={handleSubmit(({ cancellationDetail, cancellationReason }) =>
          handleRemoveAppointment(cancellationDetail, cancellationReason.value),
        )}
      >
        {/*<form onSubmit={handleSubmit(handleRemoveAppointment)}>*/}
        <QuestionContainer>
          <Controller
            name={'cancellationReason'}
            as={SelectInput}
            control={control}
            defaultValue={''}
            label={'Cancellation Reason*'}
            width={'50%'}
            variant={'primary'}
            options={cancellationOptions}
          />
          <ErrorMessage
            as={ErrorText}
            errors={errors}
            name="cancellationReason"
            message={
              errors.cancellationReason?.label?.message || errors.cancellationReason?.value?.message
            }
          />
        </QuestionContainer>
        <QuestionContainer>
          <Controller
            name={'cancellationDetail'}
            as={StyledTextAreaInput}
            control={control}
            defaultValue={''}
            label={'Cancellation Detail*'}
          />
          <ErrorMessage name={'cancellationDetail'} errors={errors} as={ErrorText} />
        </QuestionContainer>
        <ButtonRow>
          <Button variant="secondary" size="medium" onClick={() => setRemoveModal(false)}>
            No
          </Button>
          <Button variant="primary" size="medium" type="submit">
            Yes
          </Button>
        </ButtonRow>
      </form>
    </Modal>
  );
};
