import React, { useContext } from 'react';
import { TextAreaInput } from 'shared-library';
import { StudyContext } from 'packages/booking-contexts';

export const SalesNotes = () => {
  const { salesNotes, setSalesNotes } = useContext(StudyContext);
  return (
    <TextAreaInput
      label="Study Notes:"
      id="salesNotes"
      value={salesNotes}
      as="textarea"
      placeholder="Comments will be added to the study Notes and Activities"
      rows={4}
      onChange={setSalesNotes}
    />
  );
};
