import React from 'react';
import styled from 'styled-components';
import { Text } from 'shared-library';
import { QuestionLayoutRow } from './Layout';

const MsgDiv = styled.div<{ color: string }>`
  margin: 1em 2em;
  background-color: ${({ color }) => color};
`;

const BlockText = styled(Text)`
  ${({ bold }) => !!bold && 'padding-right: 2px;'}
`;

const InlineText = styled(Text)`
  display: inline-block;
  ${({ bold }) => !!bold && 'padding-right: 2px;'}
`;

interface InfoMessageProps {
  bold: boolean;
  text: string;
  backgroundColor: string;
}

const InfoMessage: React.FC<InfoMessageProps> = ({
  bold,
  text,
  backgroundColor,
}: InfoMessageProps) => (
  <QuestionLayoutRow>
    <MsgDiv color={backgroundColor} className="style-msg">
      {bold && (
        <BlockText weight="700" size="xsmall" bold>
          {bold}
        </BlockText>
      )}

      <InlineText size="xsmall">{text}</InlineText>
    </MsgDiv>
  </QuestionLayoutRow>
);

export default InfoMessage;
