import axios from 'axios';
import moment from 'moment-timezone';

export const getAvailableTimes = (locationId, skuId, startDate, spanOfDays, memberType) => {
  let url = `/api/v1/locations/${locationId}/calendar?skuID=${skuId}`;

  if (startDate) {
    url += `&startDate=${startDate}`;
  }

  if (spanOfDays) {
    url += `&span=${spanOfDays}`;
  }
  
  if (memberType) {
    url += `&memberType=${memberType}`;
  } 

  return axios({
    method: 'GET',
    url,
  });
};

export const checkIfCurrentWeek = (sunday) => {
  const saturday = moment(sunday).day(6);
  return sunday <= moment() && moment() < saturday;
};

export const getWeek = (start, weekNumber) => {
  const dateAdjustment = 7 * weekNumber;
  const sunday = moment(start).day(dateAdjustment);
  const current = checkIfCurrentWeek(sunday);

  return {
    current,
    month: sunday.format('MMM'),
    date: sunday.date(),
    unixTimestamp: sunday.unix(),
    weekNumber,
  };
};

export const getFutureMomentObj = (month, year) => {
  const momentObj = moment().set({ year, month, date: 1 });

  if (moment().diff(momentObj, 'month') === 0 && moment().month() === month) {
    momentObj.set({ date: moment().date() });
  }

  return momentObj;
};
